import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Tooltip } from '@mui/material';
import { getWallets } from '@talismn/connect-wallets';
import { dotWalletConnectHook } from "../../hooks/dotWalletHook";


export const DotWalletConnectPopup = () => {

  const [open, setOpen] = useState(false);
  const [ subscribeAccounts, setsubscribeAccounts ] = useState();
  const [ dotWallets, setDotWallets ] = useState();
  const [ dotWallet, setDotWallet ] = dotWalletConnectHook();

  const handleOpen = () => {
    setOpen(true);
    pollWallets();
  };
  const handleClose = () => {
    setOpen(false);
  };

  const pollWallets = async () =>{
    const installedWallets = getWallets().filter(wallet => wallet.installed);
    // const installedWallets = getWallets()
    console.log("installedWallets", installedWallets);
    setDotWallets(installedWallets);
  };

  const connectWallet = async(wallet) => {
    console.log("selected wallet", wallet);
    wallet.enable("mandala").then(() => {
      wallet.subscribeAccounts(( accounts ) => {
        // do anything you want with the accounts provided by the wallet
        console.log("got wallet accounts", accounts)
        setsubscribeAccounts(accounts[0]);
        setDotWallet(JSON.stringify(accounts[0]));
      })
    })
  };

  return (
    <>
      <Tooltip title="Select and Connect a DOT wallet here.">
        <Button onClick={handleOpen} variant="contained" style={{ color: "#fff", margin: 10 }}>
          Connect DOT Wallet
        </Button>
      </Tooltip>    
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Connect to Wallet
        </DialogTitle>
        <DialogContent>
          <>
          {
            dotWallets && dotWallets.map((wallet, index) => 
              <>  
                <Button key={index} onClick={()=>connectWallet(wallet)} className="rainbow-box" style={{ margin: "10px 0 0 5px", padding: 5, height: "50px", minWidth: 150, color: "#fff" }}>
                  <img src={wallet.logo.src} height="50" style={{margin: 5}}/> {wallet.title}
                </Button>
                <br />
              </>  
            )
          }
          </>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};