import { createTheme } from '@mui/material';

export const themeLight = createTheme({
    props: {
        MuiAppBar: {
          position: "relative",
        },
        MuiCard: {
          elevation: 0,
        },
      },
      palette: {
        type: "dark",
        primary: {
          main: '#463CF0'
        },
        background: {
          default: "#191B1F",
          paper: "#272A30",
        },
        error: {
          main: "#930006",
        },
      },
      typography: {
        fontFamily: [
          'Aldrich'
        ].join(','),
      },
      overrides: {
        MuiAppBar: {
          root: {
            background: "linear-gradient(180deg, rgba(255,255,255, .1), rgba(0,0,0, .4))",
          },
        },
        MuiTable: {
          root: {
            backgroundImage: "linear-gradient(180deg, rgba(255,255,255, .1), rgba(0,0,0, .4))",
          },
        },
        MuiTypography: {
          root: {
            color: "#FFFFFF",
          },
        },
        MuiButton: {
          root: {
            color: "#191C1D",
            background: "#006874",
            "&$disabled": {
              "color": "#191C1D",
              "background": "#000000"
            }
          },
          textPrimary: {
            color: "#191C1D",
            background: "#006874"
          },
          textSecondary: {
            color: "#191C1D",
            background: "#4A6367"
          },
        },
      },
  });
  
export const themeDark = createTheme({
    props: {
        MuiAppBar: {
          position: "relative",
        },
        MuiCard: {
          elevation: 0,
        },
      },
      palette: {
        primary: {
          main: '#463CF0',
          alternate: "#222836"
        },
        secondary: {
          main: "#fff"
        },
        alternate: {
          main: "#FAB016"
        },
        hover: {
          main: '#463CF0',
        },
        background: {
          default: "#191B1F",
          paper: "#272A30",
          alternate: "#1F232F"
        },
        text:{
          primary: "#FFFFFF"
        },
        error: {
          main: "#930006",
        },
      },
      typography: {
        fontFamily: [
          'Aldrich'
        ].join(','),
      },
      components: {
        MuiButton: {
          styleOverrides: {
            startIcon:{
              fontSize: '40px',
            },
            iconSizeLarge: {
              "& > *:first-child": {
                fontSize: 40
              },
            },
          },
        },
      },
      overrides: {
        MuiAppBar: {
          root: {
            background: "#00363D !important",
          },
        },
        MuiTable: {
          root: {
            background: "#00363D !important",
          },
        },
        MuiTypography: {
          root: {
            color: "#ffffff",
          },
        },
        MuiButton: {
          styleOverrides: {
          root: {
            color: "#ffffff",
            background: "#006874",
            "&$disabled": {
              "color": "#FFFFFF",
              "background": "grey"
            },
            fontSize: "40px"
          },
          startIcon: {
            fontSize: "40px"
          },
          textPrimary: {
            color: "#ffffff",
            background: "#4A6367"
          },
          textSecondary: {
            color: "#ffffff",
            background: "#BBC6EB"
          },
        },
      },
    }
});