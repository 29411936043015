import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip } from "@mui/material";
import { ToaText } from "./ToaText";
import { SelectNetworkDialog } from "../Network/SelectNetworkDialog";
import { MandalaClanAppBackendApi } from "../../api/MandalaApi";
import { connectedWallet } from "../../hooks/connectedWalletHook";
import {uuidHook} from "../../hooks/uuidHook";

export const TOADialog = ({ height }) => {
  const [open, setOpen] = useState(false);
  const [sacrificeUUID, setSacrificeUUID] = uuidHook();
  const [adaWAllet, setAdaWallet] = connectedWallet();
  const [linkUsed, setLinkUsed] = useState();

  const handleClickOpen = async () => {
    const uuid = await getQueryString("UUID");
    console.log("UUID", uuid);
    uuid && setSacrificeUUID(uuid);
    uuid && checkRefLink(uuid);
    setOpen(true);
  };

  const handleClose = () => {
    setLinkUsed();
    setOpen(false);
  };

  const getQueryString = async (name, url = window.location.href) => {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return results[2].replace(/\+/g, " ");
  };

  const checkRefLink = async (uuid) => {
    const stakeAddress = JSON.parse(adaWAllet).stakeAddr;
    const refLinkStatus = await MandalaClanAppBackendApi.sacrificeRefLink(stakeAddress, uuid, "check", "", "", "", "", "", "", "");
    refLinkStatus[0].ref_used === "true" && setLinkUsed(true);
    refLinkStatus.length === 0 && setLinkUsed(true);
    console.log("refLinkStatus", refLinkStatus);
  };

  const DisplayNoUUID = () => {
    return (
      <div>
        Looks like you didn’t use a Sacrifice link to get here.
        <br />
        <br />
        If you hold a Mandala Sovereign, Regen or Cryptonaut NFT, connect to your respective blockchain Cardano, Ethereum or Polkadot-Astar(EVM) via the buttons in the menu bar above and claim your Sacrifice links.
        <br />
        <br />
        If not, you will need to get a Sovereign, Regen or Cryptonaut NFT – or find a lucky soul with one who will share a link with you.
      </div>
    );
  };

  /*
  No Link???
  Looks like you didn’t use a Sacrifice link to get here.

  If you hold a Mandala Sovereign, Regen or Cryptonaut NFT, connect to your respective blockchain [Cardano, Ethereum or Polkadot (Astar EVM)] via the buttons in the menu bar above and claim your Sacrifice links.

  If not, you will need to get a Sovereign, Regen or Cryptonaut NFT – or find a lucky soul with one who will share a link with you.
*/

  return (
    <>
      {/* <Tooltip title="Your sacrifice link will be used up right after you press this, so please make sure you actually commit to making a sacrifice."> */}
      <Button onClick={handleClickOpen}>
        <img src={sacrificeUUID ? "assets/greensacrificebutton.png" : "assets/sacrificebutton.png" } alt="sacrifice button" height={height} />
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"lg"} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" style={{ color: "#fff" }}>
          {sacrificeUUID ? <>Scroll to the bottom of the page to agree to the terms of the Sacrifice.</> : <> No Link???</>}
        </DialogTitle>
        <DialogContent>
          {sacrificeUUID ? (
            <>
              <div style={{ margin: "0 auto" }}>
                <ToaText />
                {linkUsed === true ? <div>Looks like this link has already been used or does not exist.</div> : <SelectNetworkDialog />}
              </div>
            </>
          ) : (
            <DisplayNoUUID />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
