import React, { useState, useEffect } from 'react';
import { useAccount, useConnect, useDisconnect, useNetwork } from 'wagmi'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import Alert from "@mui/material/Alert";
import CloseIcon from '@mui/icons-material/Close';

const ConnectToWallet = () => {
  const { connector: activeConnector, isConnected, address } = useAccount();
  const { connect, connectors, error, isLoading, pendingConnector } = useConnect();
  const { disconnect } = useDisconnect();
  const { chain, chains } = useNetwork();
  // console.log("connectors", connectors);

  const connectWallet = async ( connector ) => {
    // console.log("switching to", connector);
    // console.log("chainID", chainID);
    await connect({ connector });
  };

  return(
    <>
      {
        !isConnected &&
        <div style={{textAlign: "center"}} >
          Select Wallet
        </div>
      }
      { 
        isConnected && 
        <div style={{textAlign: "center"}}>
          <div>
            Connected to { chain.name }
          </div>
          <div>
            With
          </div>
          <div>
            <Button
              disabled={!activeConnector.ready}
              key={activeConnector.id}
              onClick={() => connect({ activeConnector })}
              className="rainbow-box" style={{ margin: "10px 0 0 5px", height: "50px", minWidth: 100, color: "#fff" }}
            >
              <img src={`assets/wallets-logos/${ activeConnector.name }.svg`} height="50px" alt={ "" } />  { activeConnector.name }
            </Button>
          </div>
          <div style={{margin: "0 auto"}}>
            <Button onClick={ () => disconnect() } className="rainbow-box" style={{ margin: "10px 0 0 5px", height: "50px", color: "#fff", width: 100 }}>Disconnect </Button> 
          </div>
        </div>
      }
      {
        !isConnected && 
        connectors.map((connector) => (
        <>
          {
            connector.name !== "Injected" ?
            <div style={{textAlign: "center"}}>
              <Button
                disabled={!connector.ready}
                key={connector.id}
                onClick={ () =>  connectWallet(connector)  }
                className="rainbow-box" style={{ margin: "10px 0 0 5px", height: "50px", minWidth: 100, color: "#fff" }}
              >
                  <img src={`assets/wallets-logos/${ connector.name }.svg`} height="45px" alt={ "" } />  { connector.name } { connector.ready }
                {
                  isLoading &&
                    pendingConnector?.name === connector.name &&
                    ' (connecting)'
                }
              </Button>
              <br />
              {error && <div>{error.message}</div>}              
            </div>
            :
            <div>
              No wallet extensions detected.
            </div>
          }
        </>
      ))}      
    </>
  )
};

const ConnectedWallet = () => {
  const { connector: activeConnector, isConnected, address } = useAccount();
  const { connect, connectors, error, isLoading, pendingConnector } = useConnect();
  const { chain, chains } = useNetwork()
  // console.log("chains", chains)
  return(
    <>
      { 
        isConnected && activeConnector? 
        <div>
          {activeConnector.name} - {chain.name}
          <br />
          { address.slice(0, 8) }...
          { address.slice(-2) }
        </div>
        :
        <div>
          CONNECT
        </div>
      }
    </>
  )
};

export const EVMWallet = () => {
  const { isConnected } = useAccount();
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <> 
      <Button onClick={handleClickOpen} className="rainbow-box" style={{ margin: "10px 0 0 5px", height: "50px", minWidth: 100, color: "#fff" }}>
        <ConnectedWallet />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        PaperProps={{
          style: {
            borderRadius: "15px",
            border: "double .2em rgba(51,51,255,0.8)",
            backgroundImage: "linear-gradient(190deg, rgba(255,255,255, .1), rgba(0,0,0, .4))",
            /* filter: "drop-shadow(0 0 8px rgba(51,51,255,0.8))" */
          }
        }}
      >
        <DialogTitle id="alert-dialog-title" style={{ color: "#fff", textAlign: "center", fontSize: "16px", fontWeight: "300" }}>
          <br />
          <div style={{ color: "#fff", fontSize: "30px",textAlign: "center", margin: 5, fontWeight: "700" }}>
            Wallet Manager
          </div>
        </DialogTitle>
        <DialogContent>
          <ConnectToWallet />
        </DialogContent>
        <DialogActions>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.secondary.main,
            }}
            >
              <CloseIcon sx={{fontSize: 33}} />
            </IconButton>
        </DialogActions>
      </Dialog>        
    </>
  )
}