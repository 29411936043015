export const alchemyApiGet = async ( uri ) => {
    let settings= {};
    settings = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      // body: JSON.stringify(jsonRaw),
      redirect: 'follow',
      mode: 'cors',
    };
    try {
      const fetchResponse = await fetch(`https://eth-mainnet.g.alchemy.com/nft/v2/5tpQIWbjRPtSPERcGJkdeVklV2XO8vS0/${uri}`, settings);
      const data = await fetchResponse.json();
      // console.log(data);
      return(data);
    } catch (e) {
      // console.log(e);
      return e;
    };  
};