import React, { useState, useEffect } from "react";
// import * as CSLwasm from "@dcspark/cardano-multiplatform-lib-browser";
import * as pluts from "@harmoniclabs/plu-ts";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { connectedWallet } from "../../hooks/connectedWalletHook";
import Alert from "@mui/material/Alert";
import useMediaQuery from "@mui/material/useMediaQuery";
import { blockfrostApiGet } from "../../api/BlockfrostAPI";
let Buffer = require("buffer/").Buffer;

export const CardanoWalletConnect = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(false);
  const [wallets, setWallets] = useState([]);
  const [connectedwallet, setConnectedWallet] = connectedWallet();
  const [status, setStatus] = useState();

  const handleClickOpen = () => {
    pollWallets();
    setOpen(true);
  };

  const handleClose = () => {
    setStatus();
    setOpen(false);
  };

  const pollWallets = async (count = 0) => {
    const wallets = [];
    console.log(window.cardano);
    for (const key in window.cardano) {
      if (window.cardano[key].enable && wallets.indexOf(key) === -1) {
        wallets.push(key);
      }
    }
    setWallets(wallets);
    console.log("wallets", wallets);
    if (wallets.length === 0 && count < 3) {
      setTimeout(() => {
        pollWallets(count + 1);
      }, 1000);
      return;
    }
  };

  const fixWalletName = (walletName) => {
    return (walletName.charAt(0).toUpperCase() + walletName.slice(1)).split(" ")[0];
  };

  const connectToWallet = async (selectedWallet) => {
    setStatus({ type: "info", message: `Connecting to ${selectedWallet}...` });
    try {
      const walletApi = await window.cardano[selectedWallet].enable();
      // console.log("api", walletApi);
      window.cardano[selectedWallet].onAccountChange = () => {
        // console.log("ON CHANGE!!!!");
      };
      const getUsedAddressRes = await walletApi.getUsedAddresses();
      console.log("used addresses", getUsedAddressRes[0]);

      if (getUsedAddressRes.length === 0)
        return setStatus({
          type: "warning",
          message: "Looks like you don't have any used addresses in this wallet's account.",
        });
      const walletApiVer = await window.cardano[selectedWallet].apiVersion;
      //// console.log("wallet api ver", walletApiVer);
      const walletName = window.cardano[selectedWallet].name;
      //// console.log("wallet name", walletName);
      const walletIcon = window.cardano[selectedWallet].icon;
      const baseAddress = pluts.Address.fromBytes(getUsedAddressRes[0]).toString();
      console.log("Base Address", baseAddress);
      const stakeAddrrBF = await blockfrostApiGet(`addresses/${baseAddress}`);
      console.log("stakeAddrrBF", stakeAddrrBF);
      const stakeCredsHash = pluts.Address.fromBytes(getUsedAddressRes[0]).stakeCreds.hash
      console.log("pluts address hash", stakeCredsHash);
      const stakeAddr = new pluts.StakeAddress(
        "mainnet",
        stakeCredsHash,
        "stakeKey"
      ).toString();
      console.log("stake address", stakeAddr);
      
      const newConnectedWallet = {
        selectedWallet,
        walletName: fixWalletName(walletName),
        walletIcon,
        walletApiVer,
        baseAddr: baseAddress, // address,
        stakeAddr: stakeAddrrBF.stake_address,
      };
      setConnectedWallet(JSON.stringify(newConnectedWallet));
      setStatus({
        type: "success",
        message: `Thank you for connecting your ${fixWalletName(walletName)} wallet. You are ready to Sacrifice.`,
      });
    } catch (err) {
      console.log("wallet connect error", err);
      setStatus(err);
    }
  };

  const WalletStatus = () => {
    return <>{connectedwallet === "null" ? "Select or install a wallet to connect." : "Connected to Wallet: " + JSON.parse(connectedwallet)?.walletName}</>;
  };

  useEffect(() => {
    console.log("Refreshing Wallet", typeof connectedwallet);

    connectedwallet === "null" && setConnectedWallet("null");
    connectedwallet === null && setConnectedWallet("null");
    let selectedWallet = JSON.parse(connectedwallet)?.selectedWallet;
    selectedWallet && connectToWallet(selectedWallet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectedwallet]);

  const disconnectWallet = () => {
    setConnectedWallet("null");
    connectToWallet("null");
    setStatus({
      type: "success",
      message: `Disconnected`,
    });
  };

  return (
    <>
      {connectedwallet === "null" ? (
        <Button onClick={handleClickOpen} className="rainbow-box" style={{ margin: "10px 0 0 5px", height: "50px", minWidth: 100, color: "#fff" }}>
          Connect Wallet
        </Button>
      ) : (
        <Button onClick={handleClickOpen} className="rainbow-box" style={{ margin: "10px 0 0 5px", height: "50px", minWidth: 100, color: "#fff" }}>
          <img src={JSON.parse(connectedwallet)?.walletIcon} alt={JSON.parse(connectedwallet)?.walletIcon} height="25" />
          {JSON.parse(connectedwallet)?.baseAddr.slice(0, 8)}...
          {JSON.parse(connectedwallet)?.baseAddr.slice(-2)}
        </Button>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen={fullScreen}
        maxWidth="sm"
        PaperProps={{
          style: {
            borderRadius: "15px",
            border: "double .2em rgba(51,51,255,0.8)",
            backgroundImage: "linear-gradient(190deg, rgba(255,255,255, .1), rgba(0,0,0, .4))",
            /* filter: "drop-shadow(0 0 8px rgba(51,51,255,0.8))" */
          },
        }}
      >
        <DialogTitle id="alert-dialog-title" style={{ color: "#fff", textAlign: "center", fontSize: "16px", fontWeight: "300" }}>
          <br />
          <div style={{ color: "#fff", fontSize: "30px", textAlign: "center", margin: 5, fontWeight: "700" }}>Wallet Manager</div>
          <br />
          <span style={{ color: "#463CF0", textAlign: "center", fontSize: "22px", fontWeight: "500" }}>Connection Status </span>
          <br />
          <hr />
          <br />
          <WalletStatus />
        </DialogTitle>
        <div style={{ margin: "20px" }}>
          <hr />
        </div>
        <DialogContent>
          <span style={{ color: "#fff", fontSize: "25px" }}>
            Available Wallets: <br />
          </span>
          {wallets.length > 0 ? (
            wallets.map((wallet) => (
              <>
                {wallet === "nami" && (
                  <div style={{ margin: 10, textAlign: "center" }}>
                    <Button
                      className="nami"
                      onClick={() => connectToWallet("nami")}
                      style={{
                        justifyContent: "flex-start",
                        background: "linear-gradient(90.03deg, #FFFFFF -11.86%, #349EA3 91.91%)",
                        boxShadow: "9px 9px 13px #121316,-6px -6px 15px #484d58",
                        minWidth: "400px",
                        minHeight: "50px",
                        borderRadius: "10px",
                        color: "#000",
                        fontWeight: "bold",
                        fontSize: "18px",
                        textTransform: "none",
                      }}
                    >
                      <img src="assets/wallets-logos/nami.png" height="50px" alt="Nami Icon" />
                      Nami
                    </Button>
                  </div>
                )}
                {wallet === "eternl" && (
                  <div style={{ margin: 10, textAlign: "center" }}>
                    <Button
                      className="eternl"
                      onClick={() => connectToWallet("eternl")}
                      style={{
                        justifyContent: "flex-start",
                        background: "linear-gradient(104.68deg, #FFAE17 -4.59%, #FE4F73 21.95%, #9B59FE 70.59%, #4AF8D5 101.24%)",
                        boxShadow: "9px 9px 13px #121316,-6px -6px 15px #484d58",
                        minWidth: "400px",
                        minHeight: "50px",
                        borderRadius: "10px",
                        color: "#000",
                        fontWeight: "bold",
                        fontSize: "18px",
                        textTransform: "none",
                      }}
                    >
                      <img src="assets/wallets-logos/eternl.png" height="50px" alt="eternl Icon" />
                      Eternl
                    </Button>
                  </div>
                )}
                {wallet === "flint" && (
                  <div style={{ margin: 10, textAlign: "center" }}>
                    <Button
                      className="flint"
                      onClick={() => connectToWallet("flint")}
                      style={{
                        justifyContent: "flex-start",
                        background: "linear-gradient(90.03deg, #F8F0E3 -15.78%, #FF7513 91.91%)",
                        boxShadow: "9px 9px 13px #121314,-6px -6px 15px #484d58",
                        minWidth: "400px",
                        minHeight: "50px",
                        borderRadius: "10px",
                        color: "#000",
                        fontWeight: "bold",
                        fontSize: "18px",
                        textTransform: "none",
                      }}
                    >
                      <img src="assets/wallets-logos/flint.png" height="50px" alt="flint Icon" />
                      Flint
                    </Button>
                  </div>
                )}
                {wallet === "nufi" && (
                  <div style={{ margin: 10, textAlign: "center" }}>
                    <Button
                      className="flint"
                      onClick={() => connectToWallet("nufi")}
                      style={{
                        justifyContent: "flex-start",
                        background: "linear-gradient(90.03deg, #F8F0E3 -15.78%, #FF7513 91.91%)",
                        boxShadow: "9px 9px 13px #121314,-6px -6px 15px #484d58",
                        minWidth: "400px",
                        minHeight: "50px",
                        borderRadius: "10px",
                        color: "#000",
                        fontWeight: "bold",
                        fontSize: "18px",
                        textTransform: "none",
                      }}
                    >
                      <img src="assets/wallets-logos/nufi.png" height="50px" alt="nufi Icon" />
                      Nufi
                    </Button>
                  </div>
                )}
                {wallet === "gerowallet" && (
                  <div style={{ margin: 10, textAlign: "center" }}>
                    <Button
                      className="gero"
                      onClick={() => connectToWallet("gerowallet")}
                      style={{
                        justifyContent: "flex-start",
                        background: "linear-gradient(90.03deg, #000000 -11.86%, #00E094 35.82%, #00B8B3 66.11%, #00577C 91.91%)",
                        boxShadow: "9px 9px 13px #121316,-6px -6px 15px #484d58",
                        minWidth: "400px",
                        minHeight: "50px",
                        borderRadius: "10px",
                        color: "#000",
                        fontWeight: "bold",
                        fontSize: "18px",
                        textTransform: "none",
                      }}
                    >
                      <img src="assets/wallets-logos/gero.png" height="50px" alt="gero Icon" />
                      Gero
                    </Button>
                  </div>
                )}
                {wallet === "typhoncip30" && (
                  <div style={{ margin: 10, textAlign: "center" }}>
                    <Button
                      className="typhon"
                      onClick={() => connectToWallet("typhoncip30")}
                      style={{
                        justifyContent: "flex-start",
                        background: "linear-gradient(90.03deg, #1E2C57 -13.54%, #284179 13.94%, #7693D0 69.47%, #CFD7E9 91.91%)",
                        boxShadow: "9px 9px 13px #121316,-6px -6px 15px #484d58",
                        minWidth: "400px",
                        minHeight: "50px",
                        borderRadius: "10px",
                        color: "#000",
                        fontWeight: "bold",
                        fontSize: "18px",
                        textTransform: "none",
                      }}
                    >
                      <img src="assets/wallets-logos/typhon.png" height="50px" alt="Typhon Icon" />
                      Typhon
                    </Button>
                  </div>
                )}
              </>
            ))
          ) : (
            <>
              <div style={{ margin: 5 }}>You have no wallets installed or your browser doesn't support extensions.</div>
            </>
          )}
          {connectedwallet !== "null" && (
            <>
              <div style={{ margin: 10, textAlign: "center" }}>
                <Button
                  className="disconnect"
                  onClick={() => {
                    disconnectWallet();
                  }}
                  style={{
                    justifyContent: "flex-start",
                    background: "linear-gradient(90.03deg, #FFFFFF -11.86%, #349EA3 91.91%)",
                    boxShadow: "9px 9px 13px #121316,-6px -6px 15px #484d58",
                    minWidth: "400px",
                    minHeight: "50px",
                    borderRadius: "10px",
                    color: "#000",
                    fontWeight: "bold",
                    fontSize: "18px",
                    textTransform: "none",
                  }}
                >
                  Disconnect
                </Button>
              </div>
            </>
          )}
          <br />
          {status && (
            <Alert
              variant="outlined"
              sx={{
                fontWeight: "bold",
                fontSize: "18px",
                bgcolor: "background.default",
              }}
              severity={status.type}
            >
              {status.message}{" "}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
