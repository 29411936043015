import React, { useState, useEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Avatar, Grid, Stack, Tooltip, Box, Paper } from "@mui/material";
import { EVMWallet } from "../../ETHWallet/EthWallet";
import { ETHCoinsTable } from "./ETHCoinsTable";
import { useAccount, useNetwork } from "wagmi";
import { alchemyApiGet } from "../../../api/alchemyAPI";
import { EthereumReferalLinks } from "./EthereumReferalLinks";
import CircularProgress from "@mui/material/CircularProgress";

export const EthereumLinksDialog = ({}) => {
  const [open, setOpen] = useState(false);
  const { isConnected, address, connector: activeConnector } = useAccount();
  const { chain, chains } = useNetwork();
  const [ethAssets, setEthAssets] = useState();
  const [statusAssets, setStatusAssets] = useState();

  const handleOpen = () => {
    initAll();
    setOpen(true);
  };

  const handleClose = () => {
    setEthAssets();
    setStatusAssets();
    setOpen(false);
  };

  const getRegens = async () => {
    const genesis = "0x8f3ff4bebab846ab06e487b2aac28e12e4dbbc2c";
    const season1 = "0x6d6ee5681d835b93c94bf6ba60c26f8e00733c0e";
    console.log("isConnected", isConnected);
    console.log("address", address);
    const uri = `getNFTs?owner=${address}&contractAddresses[]=${genesis}&contractAddresses[]=${season1}&omitMetadata=false`;
    const getRegens = await alchemyApiGet(uri);
    console.log("regens", getRegens.ownedNfts);
    return getRegens.ownedNfts;
  };

  const initAll = async () => {
    let regensRes;
    if (isConnected !== null)
      setStatusAssets(
        <div>
          looking for assets <br /> <CircularProgress />{" "}
        </div>
      );
    if (isConnected !== null) regensRes = await getRegens();
    setStatusAssets();
    setEthAssets(regensRes);
  };

  const ShowWalletButton = () => {
    return (
      <>
        <Box sx={{ width: "100%" }}>
          <EVMWallet />
          {isConnected === false && (
            <div>
              <br />
              Mandala Regen holders, connect your wallet and get your Sacrifice links.
            </div>
          )}
        </Box>
        <br />
        <Box sx={{ width: "100%" }}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12}>
              {isConnected && ethAssets && ethAssets.length > 0 && <div>Regen, claim your Sacrifice links: {ethAssets.length * 3}</div>}
              {statusAssets && <div>{statusAssets}</div>}
              {isConnected && ethAssets && ethAssets.length > 0 && (
                <div>
                  <EthereumReferalLinks assets={ethAssets} />
                </div>
              )}
              {isConnected && ethAssets && ethAssets.length == 0 && <div>You do not have any Mandala Regen NFTs, so you do not have any Sacrifice links.</div>}
            </Grid>
          </Grid>
        </Box>
      </>
    );
  };

  useEffect(() => {
    initAll();
  }, [isConnected]);

  return (
    <>
      <Button onClick={handleOpen} variant="contained" style={{ color: "#fff", margin: 10 }}>
        {isConnected && activeConnector ? (
          <div>
            {activeConnector.name} - {chain.name}
            <br />
            {address.slice(0, 8)}...
            {address.slice(-2)}
          </div>
        ) : (
          <div>CONNECT ETH WALLET</div>
        )}
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"lg"} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" style={{ color: "#fff", textAlign: "center" }}>
          {isConnected == false && <>Regen?</>}
        </DialogTitle>
        <DialogContent></DialogContent>
        <div style={{ textAlign: "center" }}>
          <div>
            {/*<ETHCoinsTable />*/}
            <ShowWalletButton />
          </div>
        </div>
        <DialogActions>
          <Button onClick={() => handleClose()}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
