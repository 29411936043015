import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip } from "@mui/material";
import { MandalaClanAppBackendApi } from "../../../api/MandalaApi";
import { DotWalletConnectPopup } from "../../DOTWallet/DoTWalletConnect";
import { dotWalletConnectHook } from "../../../hooks/dotWalletHook";
import rows from "./polkaCoins.json";

export const PolkadotCoinsTable = ({ reveal }) => {
  const [copyStatus, setCopyStatus] = useState({});

  const handleCopyStatus = (prop) => (event) => {
    // console.log(prop)
    navigator.clipboard.writeText(prop);
    setCopyStatus({ [prop]: event.target.value });
  };

  const columns = [
    {
      field: "image",
      headerName: "",
      width: 70,
      editable: false,
      renderCell: (rows) => <img src={rows.row.image} height="20" />, // renderCell will render the component
    },
    {
      field: "id",
      headerName: "Coin Name",
      width: 130,
      editable: false,
    },
    {
      field: "coinTicker",
      headerName: "Ticker",
      width: 130,
      editable: false,
    },
    reveal === true && {
      field: "coinAddress",
      headerName: "Sacrifice Address",
      width: 600,
      editable: false,
      renderCell: (rows) => (
        <>
          <div style={{ width: "400px" }}>{rows.row.coinAddress}</div>{" "}
          <Button value={rows.row.coinAddress} onClick={handleCopyStatus(rows.row.coinAddress)} variant="contained" style={{ color: "#fff", marginLeft: "10px" }}>
            Copy
          </Button>{" "}
          {copyStatus[rows.row.coinAddress] && "copied"}
        </>
      ),
    },
  ];

  return (
    <div style={{ height: 450, width: "90%", margin: "0 auto" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        density="compact"
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
        sx={{
          boxShadow: 2,
          border: 2,
          borderColor: "secondary.light",
          "& .MuiDataGrid-cell:hover": {
            color: "secondary.main",
          },
          color: "secondary.main",
        }}
      />
    </div>
  );
};

export const DisplayPolkadotDialog = () => {
  const [open, setOpen] = useState(false);
  const [reveal, setReveal] = useState(false);
  const [useStatus, setUsedStatus] = useState();
  const [ dotWallet, setDotWallet ] = dotWalletConnectHook();
  const [ sacrificeAddr, setSacrificeAddr ] = useState("15Q5XG6yoD5HHmYYNrTyF4SzbCUbffYsy9r5TWiXwpDKyhjj");

  const handleReveal = async () => {
    sacrificeLink();
  };

  const getQueryString = async (name, url = window.location.href) => {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return results[2].replace(/\+/g, " ");
  };

  const sacrificeLink = async () => {
    setUsedStatus("USING LINK...");
    const uuid = await getQueryString("UUID");
    console.log("UUID", uuid);
    console.log("DOT Wallet: ", JSON.parse(dotWallet).address)
    setReveal(true);
    const useLinkRes = await MandalaClanAppBackendApi.sacrificeRefLink(JSON.parse(dotWallet).address, uuid, "", "5", "", "", "", "", "", "polkadot");
    console.log("useLinkRes", useLinkRes);
    Array.isArray(useLinkRes) === true && setUsedStatus( "Thank you please make a sacrifice from " + JSON.parse(dotWallet).address );
    Array.isArray(useLinkRes) === false && useLinkRes == "used" && setUsedStatus( "This one time sacrifice link has already been used." );
    Array.isArray(useLinkRes) === false && useLinkRes == "nolink" && setUsedStatus( "Bad Link" );
  };

  const handleOpen = () => {
    setUsedStatus();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button onClick={handleOpen} style={{ backgroundColor: "transparent", height: 200 }}>
        <img src="assets/chainlogos/polkadotPink.png" alt="polkadot logo" width="200" />
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"lg"} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" style={{ color: "#fff" }}>
          Polkadot Sacrifice 
        </DialogTitle>
        <DialogContent>
          {/*<PolkadotCoinsTable reveal={reveal} />*/}
          <div style={{ textAlign: "center" }}>
            {dotWallet &&
              <>
              You have selected to make a sacrifice from the following Polkadot address:<br /> <b>{JSON.parse(dotWallet).address}</b>
              </>    
            }
            <hr />
            {
            reveal === false && dotWallet &&
              <>
              <Tooltip title="Your sacrifice link will be used up right after you press this, so please make sure you actually commit to making a sacrifice.">
                <Button onClick={() => handleReveal()} variant="contained" style={{ color: "#fff", margin: 10 }}>
                  REVEAL SACRIFICE ADDRESS
                </Button>
              </Tooltip>
              </>
            }
            { !dotWallet && 
              <>
                <div> Please Connect Polkadot Wallet to Sacrifice </div>
                <DotWalletConnectPopup />
              </>
            }
            {
              reveal === true && <div> You may now send your DOT to the following address: <br /><b>{sacrificeAddr}</b></div>
            }
            <div>
              <hr />
              Only Native DOT Sacrifice Accepted <br /> Swap Astar and other Parachain tokens here: <a href="https://polkadex.trade/" target="_blank" rel="noreferrer" style={{color: "white"}}>https://polkadex.trade/</a>
            </div>
          </div>
          <br />
          {useStatus && <div>{useStatus}</div>}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
