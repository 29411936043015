import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip } from '@mui/material';
import { MandalaClanAppBackendApi } from "../../../api/MandalaApi";
import { BitcoinWalletConnect } from '../../BitcoinWallet/BitcoinWalletConnect';
import { useBtcWalletHook } from "../../../hooks/btcWalletHook";
import { fetchCoinPrice } from "../../../api/CoinGeckoApi";

export const DisplayBitcoinTable = ( {} ) => {
  const [open, setOpen] = useState(false);
  const [ copyStatus, setCopyStatus ] = useState({});
  const [ sacrificeAddr, setSacrificeAddr ] = useState("bc1qr9qtj6jq43j06dk40jlqrpl8qvm9jdp6yhcjpd4las4ut9859nhqp4gs3u");
  const [ reveal, setReveal ] = useState("false");
  const [ useStatus, setUsedStatus ] = useState();
  const [ btcWalletHook, setBtcWalletHook ] = useBtcWalletHook();
  const [ btcAddress, setBtcAddress ] = useState("");

  const handleReveal = async () => {
    sacrificeLink();
  };

  const getQueryString = async (name, url = window.location.href) => {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return(results[2].replace(/\+/g, ' '));
  };

  const sacrificeLink = async () => {
    setUsedStatus("USING LINK...");
    const uuid = await getQueryString("UUID");
    console.log("UUID", uuid);
    const btcPrice = await fetchCoinPrice("bitcoin");
    console.log("btcPrice", btcPrice);
    const sacrificeAssets = [
      {
        "asset": "bitcoin", "checked": "true", "amount": "0", "costUSD": btcPrice.bitcoin.usd, "costBTC": "0", "totalBTC": "0", "totalUSD": "0"  
      }
    ]
    console.log("sacrificeAssets", sacrificeAssets);
    setReveal("true");
    const checkRefLinkRes = await MandalaClanAppBackendApi.sacrificeRefLink(JSON.parse(btcWalletHook).address.toLowerCase(), uuid, "check", "", "", "", "", "", "", "");
    console.log("checkRefLinkRes", checkRefLinkRes);
    if (checkRefLinkRes[0].ref_used === "true" || checkRefLinkRes.length === 0) return setUsedStatus(false);
    const useLinkRes = await MandalaClanAppBackendApi.sacrificeRefLink(JSON.parse(btcWalletHook).address.toLowerCase(), uuid, "", "5", JSON.stringify(sacrificeAssets), "", "", "", "", "bitcoin");
    console.log("useLinkRes", useLinkRes);
    Array.isArray(useLinkRes) === true && setUsedStatus( "Thank you please make a sacrifice from " + JSON.parse(btcWalletHook).address.toLowerCase() );
    Array.isArray(useLinkRes) === false && useLinkRes == "used" && setUsedStatus( "This one time sacrifice link has already been used." );
    Array.isArray(useLinkRes) === false && useLinkRes == "nolink" && setUsedStatus( "Bad Link" );
  };

  const saveBtcAddress = () => {
    setBtcWalletHook(JSON.stringify({ address: btcAddress }));
  };

  const changeWallet = () => {
    setBtcWalletHook()
    localStorage.removeItem("btcWalletHook");
  };

  const handleOpen = () => {
    setUsedStatus(); 
    setOpen(true);
  };  

  const handleClose = () => {   
    setReveal(false);
    setOpen(false);
  };

  return (
    <>
      <Button onClick={handleOpen} style={{ backgroundColor: 'transparent', height: 200 }} >
        <img src="assets/chainlogos/bitcoin.png" alt="polkadot logo" width="200" />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"lg"}   
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{color: "#fff"}}> 
          Bitcoin Sacrifice
        </DialogTitle>
        <DialogContent>
          <div>
            { 
              btcWalletHook  &&
                <>
                 You have selected to make a sacrifice from the following Bitcoin address: <b>{ JSON.parse(btcWalletHook).address.toLowerCase()}</b>
                  <hr />
                </>
            }             
            {
              reveal === "false" && btcWalletHook &&
              <Tooltip title="Your sacrifice link will be used up right after you press this, so please make sure you actually commit to making a sacrifice.">
                <Button onClick={() => handleReveal()} variant="contained" style={{ color: "#fff", margin: 10 }}>
                  REVEAL SACRIFICE ADDRESS
                </Button>
              </Tooltip>
            }
            {
              !btcWalletHook &&
                <div style={{textAlign: "center"}}>
                Please connect a Bitcoin wallet to make a sacrifice or enter Bitcoin address you will be sending BTC from.
                <br />
                <BitcoinWalletConnect />
                <br />
                OR
                <br />  
                BTC Address: <input type="text" value={btcAddress} onChange={(e)=>setBtcAddress(e.target.value)} size="50" /> <Button onClick={()=>saveBtcAddress()} variant="contained" > Save Address </Button> 
                </div>
            }
          </div>
          { reveal === "true" && <div> <br /> You may now send your BTC to the following address: <br /> <b>{ sacrificeAddr }</b>  </div> }           
          <br />
          <div>
            { useStatus && useStatus == "used" && "This one time sacrifice link has already been used."  }
            { useStatus && useStatus == "nolink" && "Bad Link" }
            { useStatus && Array.isArray(useStatus)  && "Thank you for your sacrifice." }
          </div>          
        </DialogContent>
        <DialogActions>
            <Button onClick={()=>handleClose()}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}