import { useState } from "react";
import { createStore } from "reusable";

export const dotWalletConnectHook = createStore(() => {
  // tslint:disable-next-line: react-hooks-nesting
  const [dotWalletConnectHook, setDotWalletConnectHook] = useState(localStorage.getItem("dotWalletConnectHook"));
  // tslint:disable-next-line: no-shadowed-variable
  const handleSetWallet = (dotWalletConnectHook) => {
    localStorage.setItem("dotWalletConnectHook", dotWalletConnectHook);
    return setDotWalletConnectHook(dotWalletConnectHook);
  };
  return [dotWalletConnectHook, handleSetWallet];
});
