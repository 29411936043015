import React, { useState, useRef } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { CSVLink, CSVDownload } from "react-csv";
import { CopyDialog } from "../CopyDialog";

export const ADARefTable = ({ rows }) => {
  return (
    <>
      <Button variant="contained" style={{ color: "#fff", margin: 5 }}>
        <CSVLink data={rows} style={{ color: "#fff" }}>
          Download all of your sacrifice links.
        </CSVLink>
      </Button>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 800 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Asset Name</TableCell>
              <TableCell>Link 1</TableCell>
              <TableCell>Link 2</TableCell>
              <TableCell>Link 3</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.asset} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {row.asset}
                </TableCell>
                <TableCell align="left">
                  {row.refLink1}
                  <br />
                  <CopyDialog link={row.refLink1} />
                </TableCell>
                <TableCell align="left">
                  {row.refLink2}
                  <br />
                  <CopyDialog link={row.refLink2} />
                </TableCell>
                <TableCell align="left">
                  {row.refLink3}
                  <br />
                  <CopyDialog link={row.refLink3} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
