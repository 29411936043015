import React from 'react';
import Link from '@mui/material/Link';
import { Typography } from "@mui/material";

function Copyright() {
  return (
    <Typography fontSize="15px" color="#FFF">
      {'© '}{new Date().getFullYear()}{' '}
      <Link underline="none" color="inherit" href="https://enterthemandala.com/" target="_blank">
         Mandala.
      </Link>{' '}
      {'All Rights Reserved.'}
    </Typography>
  );
}

export const Footer = () => {
  return (
    <>
      <div style={{
                  height: "80px",
                  left: "0",
                  bottom: "0",
                  width: "100%",
                  backgroundColor: "#000",
                  color: "white",
                  textAlign: "center",
                  maxHeight: "5%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  
                  }}>
            <Copyright />
      </div>
    </>     
  );
};
