import { useState } from "react";
import { createStore } from "reusable";

export const useBtcWalletHook = createStore(() => {
  // tslint:disable-next-line: react-hooks-nesting
  const [btcWalletHook, setBtcWallethook] = useState(localStorage.getItem("btcWalletHook"));
  // tslint:disable-next-line: no-shadowed-variable
  const handleSetWallet = (btcWalletHook) => {
    localStorage.setItem("btcWalletHook", btcWalletHook);
    return setBtcWallethook(btcWalletHook);
  };
  return [btcWalletHook, handleSetWallet];
});
