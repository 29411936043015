import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { EthereumRevealDialog } from "./EthereumRevealDialog";
import rows from "./coinsEthereum.json";

const columns = [
  {
    field: "image",
    headerName: "",
    width: 70,
    editable: false,
    renderCell: (rows) => <img src={"assets/coin-logos/ethereum/" + rows.row.image} height="20" />, // renderCell will render the component
  },
  {
    field: "id",
    headerName: "Coin Name",
    width: 130,
    editable: false,
  },
  {
    field: "coinTicker",
    headerName: "Ticker",
    width: 130,
    editable: false,
  },
  {
    field: "address",
    headerName: "Address",
    width: 375,
    editable: false,
    renderCell: (rows) => (
      <a href={"https://etherscan.io/token/" + rows.row.address} target="_blank" rel="noreferrer" style={{ color: "white" }}>
        {rows.row.address}
      </a>
    ),
  },
];
export const ETHCoinsTable = () => {
  return (
    <div style={{ height: 450, width: "90%", margin: "0 auto" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        density="compact"
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
        sx={{
          boxShadow: 2,
          border: 2,
          borderColor: "secondary.light",
          "& .MuiDataGrid-cell:hover": {
            color: "secondary.main",
          },
          color: "secondary.main",
        }}
      />
    </div>
  );
};
export const DisplayEthDialog = ({}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Button onClick={handleOpen} style={{ backgroundColor: "transparent", height: 200 }}>
        <img src="assets/chainlogos/ethereum.png" alt="ethereum logo" width="200" />
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"lg"} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" style={{ color: "#fff" }}>
          Ethereum Tokens Accepted for Sacrifice.
        </DialogTitle>
        <DialogContent>
          <ETHCoinsTable />
          <div style={{ textAlign: "center" }}>
            <EthereumRevealDialog />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
