
import { Buffer } from "buffer";

export const hex2a = (hexx) => {
    var hex = hexx.toString();//force conversion
    var str = '';
    for (var i = 0; i < hex.length; i += 2)
        str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
    return str;
};

export const a2hex = ( ascii ) => {
    let arr1 = [];
	for (let n = 0, l = ascii.length; n < l; n ++) 
     {
		let hex = Number(ascii.charCodeAt(n)).toString(16);
		arr1.push(hex);
	 }
	return arr1.join('');
};

export const fromHex = (hex) => {
    return Buffer.from(hex, "hex");
};
  
export const toHex = (bytes) => {
    return Buffer.from(bytes).toString('hex');
};