import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

export const CopyDialog = ( { link } ) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    navigator.clipboard.writeText(link);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button onClick={handleOpen} variant="contained" style={{color: "#fff"}}>
        Copy
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{color: "#fff", fontSize: 16}}> 
          Copied Link: <a style={{color: "#FFF"}} href={ link } alt="reg link" target="_blank">{ link }</a>
        </DialogTitle>
        <DialogContent>
          Paste the copied link in a new browser window and press the Sacrifice button to make your Sacrifice or send the link to somebody to do so.
        </DialogContent>
        <DialogActions>
            <Button onClick={()=>handleClose()}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
