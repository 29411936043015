export const KupoAPI = async ( uri ) => {
  localStorage.setItem("kupoHook", "https://kupomain.onchainapps.io:443");
  // localStorage.setItem("kupoHook", "https://k.growpools.io:443");
  let kupoHook = localStorage.getItem("kupoHook");
  // kupoHook === null && localStorage.setItem("kupoHook", "https://kupomain.onchainapps.io:443");
  
  let settings= {};
  settings = {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
    redirect: "follow"
  };
  try {
    const fetchResponse = await fetch(`${kupoHook}/${uri}`, settings);
    const data = await fetchResponse.json();
    return(data);
  } catch ( error ) {
    console.log( "kupoApi error", error );
    let kupoHealth = { connection_status: "disconnected" }
    sessionStorage.setItem("kupoHealth", JSON.stringify(kupoHealth) );
    return(kupoHealth);
  };
};

export const checkKupoHealth = async () => {
  try{
    const kupoHealthRes = await KupoAPI("health");
    // console.log("Kupo health", kupoHealthRes);
    sessionStorage.setItem("kupoHealth", JSON.stringify(kupoHealthRes));
    return(kupoHealthRes);
  }catch( error ){
    console.log("Kupo Health Api error", error);
    let kupoHealth = { connection_status: "disconnected" }
    sessionStorage.setItem("kupoHealth", JSON.stringify(kupoHealth) );
     return(kupoHealth);
  };
};