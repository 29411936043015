import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { ETHRefTable } from "./ETHRefTable";
import CircularProgress from "@mui/material/CircularProgress";
import { MandalaClanAppBackendApi } from "../../../api/MandalaApi";
import { useAccount } from "wagmi";
let Buffer = require("buffer/");

export const EthereumReferalLinks = ({ assets }) => {
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState();
  const { isConnected, address } = useAccount();

  const handleOpen = () => {
    generateLinks();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const a2hex = (ascii) => {
    let arr1 = [];
    for (let n = 0, l = ascii.length; n < l; n++) {
      let hex = Number(ascii.charCodeAt(n)).toString(16);
      arr1.push(hex);
    }
    return arr1.join("");
  };


  const generateLinks = async () => {
    // console.log("assets", assets);
    let rows = [];
    let assetLinks;
    let assetLinksNew;
    console.log("ETH Address", address);
    await Promise.all(
      assets.map(async (asset) => {
        let assetId = asset.title !== "" ? a2hex(asset.title) : parseInt(asset.id.tokenId, 16);
        console.log("asset id: ", assetId);
        assetLinksNew = await MandalaClanAppBackendApi.createRefLink(address.toLowerCase(), asset.contract.address, assetId, "ethereum");
        console.log("assetLinksNew", assetLinksNew);
        assetLinksNew.length === 0 && console.log("Error generating links",  parseInt(asset.id.tokenId, 16), asset);

        await rows.push({
          asset: asset.title === "" ? "Regen(Unrevealed)" : asset.title,
          refLink1: assetLinksNew[0].ref_used === "true" ? "USED" : "https://advancedsacrifice.enterthemandala.app/?UUID=" + assetLinksNew[0].ref_link,
          refLink2: assetLinksNew[1].ref_used === "true" ? "USED" : "https://advancedsacrifice.enterthemandala.app/?UUID=" + assetLinksNew[1].ref_link,
          refLink3: assetLinksNew[2].ref_used === "true" ? "USED" : "https://advancedsacrifice.enterthemandala.app/?UUID=" + assetLinksNew[2].ref_link,
        });
      })
    );
    setRows(rows);
    console.log("rows", rows);
    return rows;
  };

  return (
    <>
      <Button onClick={handleOpen}>
        <img src="assets/linksbutton.png" alt="sacrifice button" height="100" />
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth={false} maxWidth={"xl"} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" style={{ color: "#fff" }}>
          Your Ethereum Sacrifice Referral Links
        </DialogTitle>
        <DialogContent>
          {rows ? (
            <ETHRefTable rows={rows} />
          ) : (
            <div>
              Loading Links
              <br />
              <CircularProgress />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
