import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { ADARefTable } from "./ADARefTable";
import { connectedWallet } from "../../../hooks/connectedWalletHook";
import CircularProgress from "@mui/material/CircularProgress";
import { MandalaClanAppBackendApi } from "../../../api/MandalaApi";
let Buffer = require("buffer/");

export const CardanoReferalLinks = ({ assets }) => {
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState();
  const [adaWAllet, setAdaWallet] = connectedWallet();

  const handleOpen = () => {
    generateLinks();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const hex2a = (hexx) => {
    var hex = hexx.toString(); //force conversion
    var str = "";
    for (var i = 0; i < hex.length; i += 2) str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
    return str;
  };

  const a2hex = (ascii) => {
    let arr1 = [];
    for (let n = 0, l = ascii.length; n < l; n++) {
      let hex = Number(ascii.charCodeAt(n)).toString(16);
      arr1.push(hex);
    }
    return arr1.join("");
  };

  const policyID = (asset) => {
    return asset.split(".")[0];
  };

  const assetName = (asset) => {
    console.log("asset", asset);
    return asset.split(".")[1];
  };

  const mandalaName = (asset) => {
    return hex2a(asset.split(".")[1]).split(hex2a(asset.split(".")[1]).slice(-5))[0];
  };

  const mandalaNumber = (asset) => {
    return hex2a(asset.split(".")[1]).split(hex2a(asset.split(".")[1]).split(hex2a(asset.split(".")[1]).slice(-5))[0])[1];
  };

  const generateLinks = async () => {
    let rows = [];
    let assetLinks;
    let assetLinksNew;
    const stakeAddress = JSON.parse(adaWAllet).stakeAddr;
    // const stakeAddress = "stake1ux65m7rkjeesuys3rgwkd7llhclzrj20yrhm2l4ah3hvrhszz74ta";
    console.log("stake address", stakeAddress);
    await Promise.all(
      assets.map(async (asset) => {
        // console.log({ "collection": await policyID(asset.asset), "asset": await assetName(asset.asset) })
        // assetLinks = await iamxReferalAPI( "mandala/sacrifice", { "collection": await policyID(asset.asset), "asset": await assetName(asset.asset) } );
        // console.log("asset", asset);
        if (!asset.lovelaceTotal) {
          assetLinksNew = await MandalaClanAppBackendApi.createRefLink(stakeAddress, await policyID(asset.asset), await assetName(asset.asset), "cardano");
          console.log("assetLinksNew", assetLinksNew);
          // // assetLinks = [ await policyID(asset.asset) + await assetName(asset.asset),  await policyID(asset.asset) + await assetName(asset.asset), await policyID(asset.asset) + await assetName(asset.asset) ];
          await rows.push({
            asset: (await mandalaName(asset.asset)) + mandalaNumber(asset.asset),
            refLink1: assetLinksNew[0].ref_used === "true" ? "USED" : "https://advancedsacrifice.enterthemandala.app/?UUID=" + assetLinksNew[0].ref_link,
            refLink2: assetLinksNew[1].ref_used === "true" ? "USED" : "https://advancedsacrifice.enterthemandala.app/?UUID=" + assetLinksNew[1].ref_link,
            refLink3: assetLinksNew[2].ref_used === "true" ? "USED" : "https://advancedsacrifice.enterthemandala.app/?UUID=" + assetLinksNew[2].ref_link,
          });
        }
      })
    );
    setRows(rows);
    console.log("rows", rows);
    return rows;
  };

  return (
    <>
      <Button onClick={handleOpen}>
        <img src="assets/linksbutton.png" alt="sacrifice button" height="100" />
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth={false} maxWidth={"xl"} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" style={{ color: "#fff" }}>
          Your Cardano Sacrifice Referral Links
        </DialogTitle>
        <DialogContent>
          {rows ? (
            <ADARefTable rows={rows} />
          ) : (
            <div>
              Loading Links
              <br />
              <CircularProgress />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
