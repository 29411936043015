import React, { useState, useEffect } from "react";
import { AppBar, Box, Toolbar, Typography, IconButton } from "@mui/material";
import { EthereumLinksDialog } from "../CoinsPopup/Ethereum/EthereumLinksDialog";
import { CardanoLinksDialog } from "../CoinsPopup/Cardano/CardanoLinksDialog";
import { PolkadotCoinsDialog } from "../CoinsPopup/Polkadot/PolkadotLinksDialog";
import { BitcoinWalletConnect } from "../BitcoinWallet/BitcoinWalletConnect";
import {uuidHook} from "../../hooks/uuidHook";
import { TOADialog } from "../../components/Toa/TOADialog";

export const MenuBar = () => {
  const [sacrificeUUID, setSacrificeUUID] = uuidHook();

  const init = async () => {
    const uuid = await getQueryString("UUID");
    console.log("UUID", uuid);
    uuid && setSacrificeUUID(uuid);
  };

  const getQueryString = async (name, url = window.location.href) => {
    name = name.replace(/[[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return results[2].replace(/\+/g, " ");
  };

  useEffect(() => {
    init();
  }, [sacrificeUUID]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar style={{ background: "#000000", color: "#ffffff" }}>
          <div style={{ textAlign: "left", width: "45%" }}>
            {sacrificeUUID ? (
              <>
                <span style={{ position: "relative", top: "20px" }}>
                  <a href="https://enterthemandala.com/" alt="mandala homepage" target="_blank" rel="noreferrer">
                    <img src="/SriChakraRainbow.png" alt="SriChakraRainbow" height="50" />
                  </a>
                </span>
                <span style={{ position: "relative" }}>
                  <TOADialog height={75} />
                </span>
              </>
            ) : (
              <a href="https://enterthemandala.com/" alt="mandala homepage" target="_blank" rel="noreferrer">
                <img src="/SriChakraRainbow.png" alt="SriChakraRainbow" height="50" />
              </a>
            )}
          </div>
          <Box sx={{ flexGrow: 1, textAlign: "left" }}>
            <a href="https://enterthemandala.com/" alt="mandala homepage" target="_blank" rel="noreferrer">
              <img src={"/assets/MandalaHeader.png"} height="60vh" alt="MandalaLogo" />
            </a>
          </Box>

          <div>
            <CardanoLinksDialog />
          </div>
          <div>
            <EthereumLinksDialog />
          </div>
          {
          /*
          <div>
            <PolkadotCoinsDialog />
          </div>
          */
          }
          {
          /*
          <div>
            <BitcoinWalletConnect />
          </div>
          */
          }
        </Toolbar>
      </AppBar>
      <hr />
    </Box>
  );
};
