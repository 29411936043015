import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import { MandalaClanAppBackendApi } from "../../../api/MandalaApi";
import { connectedWallet } from "../../../hooks/connectedWalletHook";
// import { createSendAdaAssetTxWebWallet } from "../../ADAWallet/txbuilderCSL";
import { koiosAPI, constructKoiosProtocolParams } from "../../../api/koiosAPI";
import { KupoAPI} from "../../../api/KupoApi";
import { splitAsset } from "../../../utils/UTXOtools";
import { txBuilder_PLUTS } from "../../ADAWallet/txbuilderPLUTS.tsx";

export const SummaryTable = ({ selectedTokens, searchTokenInfo, usdTotalSacrificed }) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Token Name</TableCell>
            <TableCell align="right">Amount</TableCell>
            <TableCell align="right">Cost Per ADA</TableCell>
            <TableCell align="right">Total ADA Worth</TableCell>
            <TableCell align="right">Mandala Token Reward</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {selectedTokens &&
            selectedTokens.map((row) => (
              <TableRow key={row.asset} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                {row.checked === true && (
                  <>
                    <TableCell component="th" scope="row">
                      {row.asset !== "lovelaceTotal" && searchTokenInfo(splitAsset(row.asset)[0])[0].name}
                      {row.asset === "lovelaceTotal" && "ADA"}
                    </TableCell>
                    <TableCell align="right">{row.asset !== "lovelaceTotal" ? row.amount + " " + searchTokenInfo(splitAsset(row.asset)[0])[0].coinTicker : row.amount + " ADA"}</TableCell>
                    <TableCell align="right">{row.costADA}</TableCell>
                    <TableCell align="right">{row.totalADA.toFixed(6)}</TableCell>
                    <TableCell align="right">{(row.totalUSD * 5).toFixed(6)}</TableCell>
                  </>
                )}
              </TableRow>
            ))}
          <TableRow key={"total"} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell component="th" scope="row">
              Total Mandala Token Sacrifice Reward
            </TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right">{(usdTotalSacrificed * 5).toFixed(6)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const CardanoRevealDialog = ({ selectedTokens, searchTokenInfo }) => {
  const [open, setOpen] = useState(false);
  const [copyStatus, setCopyStatus] = useState({});
  const [sacrificeAddr, setSacrificeAddr] = useState("addr1q95jv3tm8h37857ur0e4rtsfmh4f3cnnr3det2th8y0mdlw6esc8yrepe5vuqt0rlyhjxd6y0nz7wcyzt3nk5av872ls3wzqgk");
  // const [sacrificeAddr, setSacrificeAddr] = useState("addr1qxt3z9ee72mt0g6emykxkdlsha3d2m55d3wz6d6z5zayemg45zxjxlmnq3s4eqz5wx3z4vax4m6hl5klzkj85dugqkxsks96sh");
  const [useStatus, setUsedStatus] = useState();
  const [showReveal, setShowReveal] = useState();
  const [adaWAllet, setAdaWallet] = connectedWallet();
  const [adaTotalSacrificed, setAdaTotalSacrificed] = useState(0);
  const [usdTotalSacrificed, setUsdTotalSacrificed] = useState(0);
  const [txInfo, setTxInfo] = useState();
  const [checkedSelectedTokens, setCheckedSelectedTokenss] = useState([]);

  const handleOpen = () => {
    // sacrificeLink();
    calculateTotals();
    // console.log("selectedTokens", selectedTokens);
    setOpen(true);
  };

  const handleClose = () => {
    setUsedStatus();
    setShowReveal();
    setCheckedSelectedTokenss([]);
    setOpen(false);
  };

  const handleCopyStatus = (prop) => (event) => {
    // console.log(prop)
    navigator.clipboard.writeText(prop);
    setCopyStatus({ ...copyStatus, [prop]: event.target.value });
  };

  const getQueryString = async (name, url = window.location.href) => {
    name = name.replace(/[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return results[2].replace(/\+/g, " ");
  };

  const calculateTotals = () => {
    let totalUSDSacrificed = 0;
    let totalADASacrificed = 0;
    let checkedSelectedTokensNew = [];

    selectedTokens.map((token) => {
      token.checked === true && checkedSelectedTokensNew.push(token);
    });

    checkedSelectedTokensNew.map((token) => {
      totalUSDSacrificed = token.totalUSD + totalUSDSacrificed;
      totalADASacrificed = token.totalADA + totalADASacrificed;
    });
    console.log("totalUSDSacrificed", totalUSDSacrificed);
    console.log("totalADASacrificed", totalADASacrificed);
    setUsdTotalSacrificed(totalUSDSacrificed);
    setAdaTotalSacrificed(totalADASacrificed);
    totalADASacrificed < 1 && console.log("Please make your sacrifice more than a total of 1 ADA.");
    console.log("checkedSelectedTokensNew", checkedSelectedTokensNew);
    setCheckedSelectedTokenss(checkedSelectedTokensNew);
  };

  const sacrificeLink = async (runTx) => {
    console.log("runTx", runTx);
    const uuid = await getQueryString("UUID");
    const stakeAddress = JSON.parse(adaWAllet).stakeAddr;
    try {
      const checkRefLinkRes = await MandalaClanAppBackendApi.sacrificeRefLink(stakeAddress, uuid, "check", "", "", "", "", "", "", "");
      console.log("checkRefLinkRes", checkRefLinkRes);
      if (checkRefLinkRes[0].ref_used === "true" || checkRefLinkRes.length === 0) return setUsedStatus({ success: "used", message: "Link already used or does not exist." });
      const useLinkRes = await MandalaClanAppBackendApi.sacrificeRefLink(
        stakeAddress,
        uuid,
        runTx.hash.toString(),
        "5",
        JSON.stringify(checkedSelectedTokens),
        runTx.toCbor().toString(),
        adaTotalSacrificed.toString(),
        usdTotalSacrificed.toString(),
        (usdTotalSacrificed * 5).toString(),
        "cardano"
      );
      console.log("useLinkRes", useLinkRes);
      useLinkRes === "ok" && setUsedStatus({ success: "ok", message: "Sacrifice Success" });
      // setUsedStatus({ success: "used", message: "Link already used or does not exist." });
    } catch (error) {
      console.log("sacrificeLinkdb error", error);
      return setUsedStatus({ success: "errorDB", message: "There Was an Error" });
    }
  };

  /*
  const createSacrificeTX = async (selectedTokens, sacrificeAddr) => {
    const uuid = await getQueryString("UUID");
    const stakeAddress = JSON.parse(adaWAllet).stakeAddr;
    const checkRefLinkRes = await MandalaClanAppBackendApi.sacrificeRefLink(stakeAddress, uuid, "check", "", "", "", "", "", "", "");
    // console.log("checkRefLinkRes", checkRefLinkRes);
    if (checkRefLinkRes[0].ref_used === "true" || checkRefLinkRes.length === 0) {
      setUsedStatus({ success: "used", message: "Link already used or does not exist." });
      return;
    }
    setUsedStatus({ success: "tx", message: "CREATING TX..." });
    try {
      const runTx = await createSendAdaAssetTxWebWallet(selectedTokens, sacrificeAddr);
      // console.log("runTx", runTx);
      setUsedStatus(runTx);
      runTx && runTx.success === "ok" && sacrificeLink(runTx);
      runTx && runTx.success === "ok" && setUsedStatus({ success: "ok", message: "Sacrifice Success" });
      runTx && runTx.success === "ok" && setTxInfo(runTx);
      return;
    } catch (error) {
      console.log("createSacrificeTX error", error);
      setUsedStatus({ success: "errorTX", message: "There Was an Error" });
      return;
    }
  };
  */

  const buildTx = async (selectedTokens) => {
    const changeAddress = JSON.parse(adaWAllet).baseAddr;    
    /*
    ##########################################################################################################
    Fetching ProtocolParams from Koios API V1
    #############################d############################################################################
    */
    const protocolParamsKoiosRes = await koiosAPI("epoch_params");
    // console.log("protocolParamsKoiosRes", protocolParamsKoiosRes[0]);
    
    /*
    ##########################################################################################################
    Parse PraotocolParams from Ogmios
    #############################d############################################################################
    */
    // const ogmiosHealthRes = await ogmiosHealth();
    // console.log("ogmiosHealthRes", ogmiosHealthRes);

    /*
    ##########################################################################################################
    Parse PraotocolParams from Koios API V1
    #############################d############################################################################
    */
    const defaultProtocolParameters = await constructKoiosProtocolParams(protocolParamsKoiosRes);
    // console.log("defaultProtocolParameters", defaultProtocolParameters);

    /*
    ##########################################################################################################
    CIP30 getUtxos() method CBORs example for now.
    ##########################################################################################################
    */
    let cborInputs = [];
    
    /*
    ##########################################################################################################
    Use when using UTXO info from other sources like Kupo indexer or BLockfrost
    #############################d############################################################################
    */
    const stakeAddress = JSON.parse(adaWAllet).stakeAddr;
    let kupoInputs = await KupoAPI(`matches/${stakeAddress}?unspent`);
    console.log("kupoInputs", kupoInputs);

    /*
    ##########################################################################################################
    Output utxos
    #############################d############################################################################
    */
    console.log("selectedTokens", selectedTokens)
    /*
    const utxoOutputs = [
      {
        address: sacrificeAddr,
        value: {
          coins: 1000000,
          assets: {
            "b88d9fe270b184cf02c99b19ffa5ab0181daeff00c52811c6511c12a.4d65726b616261232d33": 1,
            "b88d9fe270b184cf02c99b19ffa5ab0181daeff00c52811c6511c12a.4d65726b616261232d32": 1,
            "b88d9fe270b184cf02c99b19ffa5ab0181daeff00c52811c6511c12a.4d65726b616261232d31": 1,
            "b812d5a466604bcc25d2313183c387cebf52302738b5a178daf146f0.4d616e64616c612332": 1,
            "b812d5a466604bcc25d2313183c387cebf52302738b5a178daf146f0.4d616e64616c612331": 1,
            "b812d5a466604bcc25d2313183c387cebf52302738b5a178daf146f0.4d616e64616c612333": 1,
            "b812d5a466604bcc25d2313183c387cebf52302738b5a178daf146f0.4d616e64616c612334": 1
          }
        }
      }
    ];
    */
    let utxoOutputs = [];
    let utxo = {};
    let assets =  {};
    let coins = 0
    await selectedTokens.map((token) => 
      token.checked === true && token.asset !== "lovelaceTotal" && (assets[token.asset] = token.amount * (10 ** token.decimals))
    );
    await selectedTokens.map((token) => {
      token.checked === true && token.asset === "lovelaceTotal" && (coins = (token.amount * 1000000))
      token.checked === false && token.asset === "lovelaceTotal" && (coins = 1000000)
    });
    console.log("coins", coins);
    utxo = {
      address: sacrificeAddr,
      value: {
        coins,
        assets: assets
      }
    };
    utxoOutputs.push(utxo);
    console.log("utxoOutputs", utxoOutputs);
    
    /*
    ##########################################################################################################
    Metadata to include with teh TX.
    #############################d############################################################################
    */
    const metadata = {
      label: 420,
      properties: {
          type: "mandala_secret_sacrifice",
          message: "Another sacrifice has been made. Welcome to the Mandala.",
          website: "https://www.enterthemandala.com/",
          message2: "TX Crafted With PLU-TS"
      }
    };

    const runTx = await txBuilder_PLUTS(defaultProtocolParameters, kupoInputs, cborInputs, utxoOutputs, changeAddress, metadata);
    console.log("runTx", runTx);
    runTx !== undefined && runTx.isComplete && sacrificeLink(runTx);
  };

  return (
    <>
      <Tooltip title="View your sacrifice summary and send it to your wallet.">
        <Button onClick={handleOpen} variant="contained" style={{ color: "#fff", margin: 10 }}>
          SACRIFICE
        </Button>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"lg"} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" style={{ color: "#fff" }}>
          Cardano Sacrifice Summary
        </DialogTitle>
        <DialogContent>
          <>
            <div>
              <SummaryTable selectedTokens={selectedTokens} searchTokenInfo={searchTokenInfo} usdTotalSacrificed={usdTotalSacrificed} />
            </div>
            {adaTotalSacrificed < 1 ? (
              <div>Please make your sacrifice at least more than a total of 1 ADA.</div>
            ) : (
              <div>
                <Button onClick={() => buildTx(selectedTokens, sacrificeAddr)/*createSacrificeTX(selectedTokens, sacrificeAddr)*/} variant="contained" style={{ color: "#fff", margin: 10 }}>
                  SUBMIT
                </Button>
              </div>
            )}
            {useStatus && useStatus.success === "ok" && (
              <>
                <div>
                  Thank you for your Sacrifice summarized above. This is your TX Hash:{" "}
                  <a href={"https://cardanoscan.io/transaction/" + txInfo.txHash} target="_blank" rel="noreferrer" style={{ color: "white" }}>
                    {txInfo.txHash}
                  </a>
                </div>
              </>
            )}
            {useStatus && useStatus.success === "tx" && (
              <>
                <div>Creating TX...</div>
              </>
            )}
            {useStatus && useStatus.success === "used" && (
              <>
                <div>"Link already used or does not exist..."</div>
              </>
            )}
            {useStatus && useStatus.success === "errorTX" && (
              <>
                <div>There was an error with the TX</div>
              </>
            )}
            {useStatus && useStatus.success === "errorDB" && (
              <>
                <div>There was an error with the recording to DB contact Mandala Team.</div>
              </>
            )}
          </>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
