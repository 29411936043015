import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Box } from "@mui/material";
import { DisplayCardanoDialog } from "../CoinsPopup/Cardano/CardanoCoinsTable";
import { DisplayEthDialog } from "../CoinsPopup/Ethereum/ETHCoinsTable";
import { DisplayPolkadotDialog } from "../CoinsPopup/Polkadot/PolkadotCoinsTable";
import { DisplayBitcoinTable } from "../CoinsPopup/Bitcoin/BitcoinTable";

export const SelectNetworkDialog = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button onClick={handleOpen} variant="contained" style={{ color: "#fff" }}>
        I Agree
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"lg"} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" style={{ color: "#fff", textAlign: "center" }}>
          Select the chain you want to Sacrifice on.
        </DialogTitle>
        <DialogContent>
          <Box sx={{ width: "100%", textAlign: "center" }}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={4}>
                <DisplayCardanoDialog />
              </Grid>
              <Grid item xs={4}>
                <DisplayEthDialog />
              </Grid>
              <Grid item xs={4}>
                <DisplayPolkadotDialog />
              </Grid>
              <Grid style={{ margin: "0 auto" }} item xs={4}>
                <DisplayBitcoinTable />
              </Grid>
            </Grid>
          </Box>
          {/*
          <br />
          <div style={{ textAlign: "center", fontSize: 18 }}>
            :: The Mandala token will be minted proportional to the amount Sacrificed on each chain. :: <br /> :: Let the games begin! ::
          </div>
          */}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
