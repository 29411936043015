import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Box, TextField, Grid, Checkbox } from "@mui/material";
import { CardanoRevealDialog } from "./CardanoRevealDialog";
import { connectedWallet } from "../../../hooks/connectedWalletHook";
import { KupoAPI } from "../../../api/KupoApi";
import { parseUtxos, parseMetadata, parsePolicies, splitAsset } from "../../../utils/UTXOtools";
import { hex2a } from "../../../utils/hextools";
import CircularProgress from "@mui/material/CircularProgress";
import { CardanoWalletConnect } from "../../ADAWallet/CardanoWalletConnect";
import { fetchCoinPrice } from "../../../api/CoinGeckoApi";
import { getTokenPrice, getAdaPrice } from "../../../api/TapToolsAPI";
import rows from "./coinsCardano.json";

const CardanoCoinsTable = ({ newRows }) => {
  const columns = [
    {
      field: "image",
      headerName: "",
      width: 70,
      editable: false,
      renderCell: (rows) => <img src={"assets/coin-logos/cardano/" + rows.row.image} height="20" alt={rows.row.id} />, // renderCell will render the component
    },
    {
      field: "id",
      headerName: "Coin Name",
      width: 130,
      editable: false,
    },
    {
      field: "coinTicker",
      headerName: "Ticker",
      width: 130,
      editable: false,
    },
    {
      field: "policy",
      headerName: "Policy ID",
      width: 500,
      editable: false,
    },
    /*
    {
      field: "costUSD",
      headerName: "Price USD",
      width: 80,
      editable: false,
    },
    */
    {
      field: "costADA",
      headerName: "Price ADA",
      width: 100,
      editable: false,
    },
  ];

  return (
    <>
      <div style={{ textAlign: "center", fontSize: "1em", fontWeight: "bold" }}>
        Powered By:
        <br />
        <a href="https://taptools.io/" target="_blank" rel="noreferrer" style={{ color: "#FFFFFF", textDecoration: "none" }}>
          <img src="assets/dexLogos/taptools.png" alt="Taptools" height={50} /> <span style={{ position: "relative", top: "-20px" }}>TapTools.io</span>
        </a>
      </div>
      <div style={{ height: 450, width: "90%", margin: "0 auto" }}>
        <DataGrid
          rows={newRows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          density="compact"
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          sx={{
            boxShadow: 2,
            border: 2,
            borderColor: "secondary.light",
            "& .MuiDataGrid-cell:hover": {
              color: "secondary.main",
            },
            color: "secondary.main",
          }}
        />
      </div>
    </>
  );
};

const DisplayOwnedCoins = () => {
  const [adaWAllet, setAdaWallet] = connectedWallet();
  const [adaAssets, setAdaAssets] = useState();
  const [statusAssets, setStatusAssets] = useState();
  const [selectedTokens, setSelectedTokens] = useState([]);
  const [newRows, setNewRows] = useState(rows);
  const [costAdaUSD, setCostAdaUSD] = useState(0);

  const getAddressUtxos = async () => {
    const stakeAddress = JSON.parse(adaWAllet).stakeAddr;
    // console.log("stakeAddress", stakeAddress);
    // const address = "stake1uxpxcyjnmq4636cfnw0au6r6y6wyqnfvyr6czawjctglxlcmqalx6";
    // const address = "addr1q8pyhae0uwm3a0thhr3sar897fty7efkgp8qycqcupjt635h4jj60xtktmzm5a0k3nakxyg9xujh3vfn920zx5aw4fssgaeedp";
    const addressUtxos = await KupoAPI(`matches/${stakeAddress}?unspent`);
    // console.log("addressUtxos", addressUtxos);
    return addressUtxos;
  };

  const initAll = async () => {
    let rawUtxos = [];
    let parsedUtxos = [];
    let parsedUtxoMetadata = [];
    let parsedPolicies = [];
    let sacrificeTokenpolicies = [];
    setSelectedTokens([]);
    setAdaAssets();

    rows.map((row) => sacrificeTokenpolicies.push(row.policy + "." + row.tokenName));
    // console.log("sacrificeTokenpolicies", sacrificeTokenpolicies);
    if (adaWAllet !== "null")
      setStatusAssets(
        <div>
          looking for sacrifice assets <br /> <CircularProgress />{" "}
        </div>
      );
    if (adaWAllet !== "null") rawUtxos = await getAddressUtxos();
    // console.log("rawUtxos", rawUtxos);
    if (rawUtxos.length > 0) parsedUtxos = await parseUtxos(rawUtxos);
    // console.log("parsedUtxos", parsedUtxos);
    if (parsedUtxos.length > 0) parsedUtxoMetadata = await parseMetadata(parsedUtxos);
    // console.log("parsedUtxoMetadata", parsedUtxoMetadata);
    if (parsedUtxoMetadata.length > 0) parsedPolicies = await parsePolicies(parsedUtxoMetadata, sacrificeTokenpolicies);
    console.log("parsedPolicies", parsedPolicies);
    setStatusAssets();
    setAdaAssets(parsedPolicies);

    const tokenPricesarray = await tokenPrices(sacrificeTokenpolicies);
    // console.log("tokenPricesarray", tokenPricesarray);

    const costOfAdaRes = await getAdaPrice();
    const costOfAdaUSD = costOfAdaRes.price;
    // console.log("costOfAdaUSD", costOfAdaUSD);
    setCostAdaUSD(costOfAdaUSD);
    await Promise.all(
      newRows.map(async (row) => {
        const tokenPriceAda = tokenPricesarray.filter((token) => {
          return Object.keys(token)[0] === row.policy + row.tokenName;
        });
        let fetchCoinPriceRes = tokenPriceAda[0][row.policy + row.tokenName];
        console.log("fetchCoinPriceRes", fetchCoinPriceRes);
        let coinPriceUsd = costOfTokenInUSD(costOfAdaUSD, fetchCoinPriceRes);
        // console.log("coinPriceUsd", coinPriceUsd);
        row.costUSD = coinPriceUsd.toFixed(6);
        row.costADA = fetchCoinPriceRes.toFixed(6);
        
      })
    );
    // console.log("newRows", newRows);
    setNewRows(newRows);
    // console.log("parsedPolicies", parsedPolicies);
    await Promise.all(
      parsedPolicies.map(async (policy) => {
        let costUSD;
        let costADA;
        let decimals = 0;
        // console.log("policy", policy);
        // policy.asset && console.log(newRows.filter((row) => row.policy == splitAsset(policy.asset)[0])[0].costUSD);
        if (policy.asset) costUSD = newRows.filter((row) => row.policy == splitAsset(policy.asset)[0])[0].costUSD;
        if (policy.asset) costADA = newRows.filter((row) => row.policy == splitAsset(policy.asset)[0])[0].costADA;
        if (policy.asset) decimals = newRows.filter((row) => row.policy == splitAsset(policy.asset)[0])[0].decimals;
        policy.asset && setSelectedTokens((selectedToken) => [...selectedToken, { asset: policy.asset, checked: false, amount: 0, costUSD, costADA, totalADA: 0, totalUSD: 0, decimals }]);
        policy.lovelaceTotal &&
          setSelectedTokens((selectedToken) => [...selectedToken, { asset: "lovelaceTotal", checked: false, amount: 0, costUSD: costOfAdaUSD, costADA: 1, totalADA: 0, totalUSD: 0 }]);
      })
    );
  };

  const tokenPrices = async (sacrificeTokenpolicies) => {
    let tokenPricesArray = [];
    let tokenPricesRes = await getTokenPrice(sacrificeTokenpolicies);
    // console.log("tokenPricesRes", tokenPricesRes);
    Promise.all(
      Object.entries(tokenPricesRes).map((tokenPrice) => {
        tokenPricesArray.push({
          [tokenPrice[0]]: tokenPrice[1],
        });
        // console.log("tokenPrice", tokenPrice);
      })
    );
    console.log("tokenPricesArray", tokenPricesArray);
    return tokenPricesArray;
  };

  const costOfTokenInAda = (costOfAdaUSD, coinPriceUsd) => {
    // console.log("coinPriceUsd", coinPriceUsd);
    // console.log("costOfAdaUSD", costOfAdaUSD);
    let tokenAdaCost = coinPriceUsd / costOfAdaUSD;
    // console.log("tokenAdaCost", tokenAdaCost);
    tokenAdaCost === Infinity && (tokenAdaCost = 0);
    isNaN(tokenAdaCost) && (tokenAdaCost = 0);
    // console.log("tokenAdaCost", tokenAdaCost);
    return tokenAdaCost;
  };

  const costOfTokenInUSD = (costOfAdaUSD, coinPriceAda) => {
    const tokenUSDPrice = costOfAdaUSD * coinPriceAda;
    return tokenUSDPrice;
  };

  const searchTokenInfo = (policy) => {
    let filterTokens = rows.filter((token) => {
      return token.policy === policy;
    });
    // console.log("searchTokenInfo", filterTokens);
    return filterTokens;
  };

  const searchSelectedToken = (policy) => {
    let filterSelectedTokens = selectedTokens.filter((token) => {
      return token.asset === policy;
    });
    // console.log("searchSelectedToken", filterSelectedTokens);
    return filterSelectedTokens[0];
  };

  const handleCheckbox = (event, token) => {
    // console.log("event", event.target.checked);
    // console.log("token", token);
    // console.log("selectedToken", selectedToken);
    let newChecked = event.target.checked;
    //event.target.checked === false && (newChecked = true);
    //event.target.checked === true && (newChecked = false);

    let newSelectedTokenList = selectedTokens;
    setSelectedTokens();

    if (token.asset) {
      newSelectedTokenList.map((newSelectedTokenList) => {
        if (newSelectedTokenList.asset === token.asset) {
          newSelectedTokenList.checked = newChecked;
          // console.log("newChecked", newChecked);
        }
      });
    }

    if (token.lovelaceTotal) {
      newSelectedTokenList.map((newSelectedTokenList) => {
        // console.log("newSelectedTokenList", newSelectedTokenList);
        if (newSelectedTokenList.asset === "lovelaceTotal") {
          newSelectedTokenList.checked = newChecked;
        }
      });
    }

    console.log("newSelectedTokenList", newSelectedTokenList);
    setSelectedTokens(newSelectedTokenList);
  };

  const tokenAmountText = (event, token) => {
    let newSelectedTokenList = selectedTokens;
    // console.log("tokenAmountText", token);
    if (token.asset) {
      newSelectedTokenList.map((newSelectedTokenList) => {
        if (newSelectedTokenList.asset === token.asset) {
          newSelectedTokenList.amount = event.target.value.toString();
          newSelectedTokenList.totalADA = event.target.value * newSelectedTokenList.costADA;
          newSelectedTokenList.totalUSD = event.target.value * newSelectedTokenList.costUSD;
          event.target.value > 0 && handleCheckbox({ target: { checked: true } }, token);
          event.target.value <= 0 && handleCheckbox({ target: { checked: false } }, token);
        }
      });
    }

    if (token === "lovelaceTotal") {
      newSelectedTokenList.map((newSelectedTokenList) => {
        // console.log("tokenAmnewSelectedTokenListount", newSelectedTokenList);
        if (newSelectedTokenList.asset === "lovelaceTotal") {
          newSelectedTokenList.amount = event.target.value.toString();
          newSelectedTokenList.totalUSD = event.target.value * newSelectedTokenList.costUSD;
          newSelectedTokenList.totalADA = event.target.value * newSelectedTokenList.costADA;
          event.target.value > 0 && handleCheckbox({ target: { checked: true } }, { lovelaceTotal: true });
          event.target.value <= 0 && handleCheckbox({ target: { checked: false } }, { lovelaceTotal: true });
        }
      });
    }
    // console.log("newSelectedTokenList", newSelectedTokenList);
    setSelectedTokens(newSelectedTokenList);
  };

  useEffect(() => {
    setSelectedTokens([]);
    setAdaAssets();
    initAll();
  }, [adaWAllet]);

  return (
    <>
      <div style={{ textAlign: "center", fontSize: "1.5em", fontWeight: "bold" }}>₳DA ${costAdaUSD.toFixed(6)}</div>
      {newRows && <CardanoCoinsTable newRows={newRows} />}
      {statusAssets && <div style={{ textAlign: "center" }}>{statusAssets}</div>}
      {adaWAllet === "null" && (
        <div style={{ textAlign: "center" }}>
          Please Connect Cardano Wallet to Sacrifice:
          <br />
          <CardanoWalletConnect />
        </div>
      )}
      {adaWAllet !== "null" && adaAssets && adaAssets.length > 0 && (
        <div style={{ minHeight: 200, width: "90%", margin: "0 auto", border: "2px solid white", textAlign: "center" }}>
          <div>Sovereign, seems like you own the following tokens for the Sacrifice. </div>
          {/*<div>Currently other than ADA sacrifices are disabled.</div>*/}

          <Grid container direction="row" alignItems="left">
            {adaAssets.map((token) => (
              <>
                {token.lovelaceTotal && (
                  <Box
                    component="form"
                    sx={{
                      "& .MuiTextField-root": { m: 1, width: "50px" },
                    }}
                    noValidate
                    autoComplete="off"
                    style={{ padding: 5 }}
                  >
                    {/*
                    <Checkbox
                      checked={console.log("checkboxstats", searchSelectedToken("lovelaceTotal"))}
                      onChange={(event) => handleCheckbox(event, token)}
                      inputProps={{ "aria-label": "controlled" }}
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 40 } }}
                    />
                    */}
                    <div>
                      <TextField
                        id="filled-number"
                        type="number"
                        InputProps={{ inputProps: { min: 0, max: token.lovelaceTotal / 1000000 } }}
                        color="success"
                        style={{ width: 170, height: 10 }}
                        label={<>ADA</>}
                        onChange={(event) => {
                          tokenAmountText(event, "lovelaceTotal");
                        }}
                        defaultValue={0}
                        size="small"
                        focused
                      />
                    </div>
                    <br />
                    <div>Total: {(token.lovelaceTotal / 1000000).toFixed(6)}</div>
                  </Box>
                )}
              </>
            ))}
            {adaAssets.map((token) => (
              <>
                {token.asset && (
                  <Box
                    component="form"
                    sx={{
                      "& .MuiTextField-root": { m: 1, width: "50px" },
                    }}
                    noValidate
                    autoComplete="off"
                    style={{ padding: 5 }}
                  >
                    {/*
                    <Checkbox
                      checked={console.log("checkboxstats", searchSelectedToken(token.asset))}
                      disabled
                      onChange={(event) => handleCheckbox(event, token)}
                      inputProps={{ "aria-label": "controlled" }}
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 40 } }}
                    />
                    */}
                    <div>
                      <TextField
                        // disabled
                        id="filled-number"
                        type="number"
                        InputProps={
                          searchTokenInfo(splitAsset(token.asset)[0])[0].decimals > 0 ? token.assetAmount / (searchTokenInfo(splitAsset(token.asset)[0])[0].decimals * 1000000) : token.assetAmount
                        }
                        color="success"
                        style={{ width: 170, height: 10 }}
                        label={
                          <>
                            {searchTokenInfo(splitAsset(token.asset)[0])[0].name} ({searchTokenInfo(splitAsset(token.asset)[0])[0].coinTicker})
                          </>
                        }
                        onChange={(event) => {
                          tokenAmountText(event, token);
                        }}
                        defaultValue={0}
                        size="small"
                        focused
                      />
                    </div>
                    <br />
                    <div>
                      Total:
                      {searchTokenInfo(splitAsset(token.asset)[0])[0].decimals > 0
                        ? (token.assetAmount / (searchTokenInfo(splitAsset(token.asset)[0])[0].decimals * 1000000)).toFixed(6)
                        : token.assetAmount}
                    </div>
                  </Box>
                )}
              </>
            ))}
          </Grid>
        </div>
      )}
      {adaWAllet !== "null" && adaAssets && adaAssets.length === 0 && (
        <div style={{ textAlign: "center" }}>Sovereign it doesn't seem like you own any Sacrificable assets, however you can still sacrifice ADA.</div>
      )}
      {adaWAllet !== "null" && adaAssets && (
        <div style={{ textAlign: "center" }}>
          <CardanoRevealDialog selectedTokens={selectedTokens} searchTokenInfo={searchTokenInfo} />
          <br />
          <div>Once you have selected which token(s) you want to Sacrifice, click the Sacrifice button above to sign your transaction with your connected wallet.</div>
          <br />
          <div>If you'd like to acquire more tokens for a Sacrifice, visit one of these Cardano DEXs. Please double-check policy IDs before you buy anything!</div>
          <br />
          <br />
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
              <Grid xs={2} sm={4} md={4} key={1}>
                <a href="https://dexhunter.io/" target="_blank" rel="noreferrer" style={{ color: "#FFFFFF", textDecoration: "none" }}>
                  <img src="https://www.dexhunter.io/_next/static/media/hunt-token.0f202821.svg" alt="dex hunter logo" height={50} />{" "}
                  <span style={{ fontSize: "40px", verticalAlign: "top", display: "inline-block" }}>DexHunter</span>
                </a>
              </Grid>
              <Grid xs={2} sm={4} md={4} key={1}>
                <a href="https://sundae.fi/" target="_blank" rel="noreferrer" style={{ color: "#FFFFFF", textDecoration: "none" }}>
                  <img src="assets/dexLogos/SVG_sundae_labs_logo_white.svg" alt="Sunda Swap logo" height={50} />
                </a>
              </Grid>
              <Grid xs={2} sm={4} md={4} key={1}>
                <a href="https://minswap.org/" target="_blank" rel="noreferrer" style={{ color: "#FFFFFF", textDecoration: "none" }}>
                  <img src="assets/dexLogos/minswap_logotype_white.svg" alt="Min Swap logo" height={50} />
                </a>
              </Grid>
            </Grid>
          </Box>
        </div>
      )}
    </>
  );
};

export const DisplayCardanoDialog = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button onClick={handleOpen} style={{ backgroundColor: "transparent", height: 200 }}>
        <img src="assets/chainlogos/cardano2.png" alt="cardano logo" width="200" />
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"lg"} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" style={{ color: "#fff" }}>
          Cardano Tokens Accepted for Sacrifice
        </DialogTitle>
        <DialogContent>
          <DisplayOwnedCoins />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
