export const CarpApi = async ( jsonRaw ) => {
  // console.log("jsonRaw", jsonRaw)
  localStorage.setItem("carpHook", "https://carpmain.onchainapps.io:443");
  let carpHook = localStorage.getItem("carpHook"); 
  // carpHook === null && localStorage.setItem("carpHook", "https://carpmain.onchainapps.io:443");
  let settings= {};
  settings = {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(jsonRaw),
    redirect: 'follow',
    mode: 'cors',
  };
  let dataJson;
  try {
    const fetchResponse = await fetch(`${carpHook}/metadata/nft`, settings);
    const data = await fetchResponse;
    if(data.status === 200) dataJson = await data.json() 
    // console.log("dataJson", dataJson);    
    return(dataJson);

  } catch ( error ) {
    console.log("carp api error", error);
    let carpHealth = { connection_status: "disconnected" }
    sessionStorage.setItem("carpHealth", JSON.stringify(carpHealth));
    return(JSON.stringify(carpHealth));   
  };  
};


/*
  // WARNING: For POST requests, body is set to null by browsers.
  var data = JSON.stringify({
    "assets": {
      "0fe7b9c1abbf139414d8e138721a85dd8d6e24ee7dc0d895587b4f57": [
        "6a633030303030303031"
      ]
    }
  });

  var xhr = new XMLHttpRequest();
  

  xhr.addEventListener("readystatechange", function() {
    if(this.readyState === 4) {
      // console.log(this.responseText);
    }
  });

  xhr.open("POST", "https://carpmain.bakon.dev/metadata/nft");
  xhr.setRequestHeader("Content-Type", "application/json");

  xhr.send(data);
*/