import * as pluts from "@harmoniclabs/plu-ts";

/*
##########################################################################################################
Fetching ProtocolParams from Koios API V1
#############################d############################################################################
*/
export const koiosAPI = async (uri) => {
    const myHeaders = new Headers();
    const koiosServer = "https://api.koios.rest/api/v1";
    const koiosApiKey = "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhZGRyIjoic3Rha2UxdTh2eHN3d2hoMnpmMHhuNG1zNzBqZmUwajl6cG1ocnh4bTQzbnFmcWFqZDg3cWdnemR3ZWQiLCJleHAiOjE3NDQ5NDM4MzIsInRpZXIiOjEsInByb2pJRCI6Im1hbmRhbGFfc2FjcmlmaWNlIn0.FpkKojznC8qoFgvqvDI-gyX5LjH4dJh9kqydqH5llXU";
    const koiosApiKey2 = "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhZGRyIjoic3Rha2UxdTh2eHN3d2hoMnpmMHhuNG1zNzBqZmUwajl6cG1ocnh4bTQzbnFmcWFqZDg3cWdnemR3ZWQiLCJleHAiOjE3NDQ5NDQyNjksInRpZXIiOjEsInByb2pJRCI6Im1hbmRhbGFfcGFnZSJ9.VPiTBoqdUdU0A9YBYgBuHwf8xkJlK_7f1M9SIO6yXNI"
    myHeaders.append("Accept", "application/json");
    myHeaders.append("authorization", koiosApiKey);
    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: 'follow',
        mode: 'cors',
    };

    try {
      const fetchResponse = await fetch(`${koiosServer}/${uri}`, requestOptions);
      console.log("fethcResponse", fetchResponse);
      const data = await fetchResponse.json();
      console.log(data);
      return data;
    } catch (e) {
      console.log(e);
      return e;
    }
  };
  
  /*
  ##########################################################################################################
  Using current epoch protocol parmaters from Koios API V1 to create a new TxBuilder instance
  #############################d############################################################################
  */
  export const constructKoiosProtocolParams = async (protocolParamsKoiosRes) => {
    const defaultProtocolParameters = {
      txFeePerByte: protocolParamsKoiosRes[0].min_fee_a,
      txFeeFixed: protocolParamsKoiosRes[0].min_fee_b,
      maxBlockBodySize: protocolParamsKoiosRes[0].max_block_size,
      maxTxSize: protocolParamsKoiosRes[0].max_tx_size,
      maxBlockHeaderSize: protocolParamsKoiosRes[0].max_bh_size,
      stakeAddressDeposit: Number(protocolParamsKoiosRes[0].key_deposit),
      stakePoolDeposit: Number(protocolParamsKoiosRes[0].pool_deposit),
      poolRetireMaxEpoch: protocolParamsKoiosRes[0].max_epoch,
      stakePoolTargetNum: protocolParamsKoiosRes[0].optimal_pool_count,
      poolPledgeInfluence: protocolParamsKoiosRes[0].influence,
      monetaryExpansion: protocolParamsKoiosRes[0].monetary_expand_rate,
      treasuryCut: protocolParamsKoiosRes[0].treasury_growth_rate,
      protocolVersion: [protocolParamsKoiosRes[0].protocol_major, protocolParamsKoiosRes[0].protocol_minor],
      minPoolCost: Number(protocolParamsKoiosRes[0].min_pool_cost),
      utxoCostPerByte: Number(protocolParamsKoiosRes[0].coins_per_utxo_size),
      costModels: {
        PlutusScriptV1: protocolParamsKoiosRes[0].cost_models.PlutusV1,
        PlutusScriptV2: protocolParamsKoiosRes[0].cost_models.PlutusV2,
      },
      executionUnitPrices: [
        new pluts.CborPositiveRational(protocolParamsKoiosRes[0].price_mem * 10000, 100), // mem
        // protocolParamsKoiosRes[0].price_mem * 100,
        new pluts.CborPositiveRational(protocolParamsKoiosRes[0].price_step * 10000000, 1e5), // cpu
      ],
      maxTxExecutionUnits: new pluts.ExBudget({ mem: protocolParamsKoiosRes[0].max_tx_ex_mem, cpu: protocolParamsKoiosRes[0].max_tx_ex_steps }),
      maxBlockExecutionUnits: new pluts.ExBudget({ mem: protocolParamsKoiosRes[0].max_block_ex_mem, cpu: protocolParamsKoiosRes[0].max_block_ex_steps }),
      maxValueSize: protocolParamsKoiosRes[0].max_val_size,
      collateralPercentage: protocolParamsKoiosRes[0].collateral_percent,
      maxCollateralInputs: protocolParamsKoiosRes[0].max_collateral_inputs,
    };
  
    return defaultProtocolParameters;
  };