import React, { useState } from "react";
import { ReusableProvider } from "reusable";
import CssBaseline from "@mui/material/CssBaseline";
import video from "./sacrificeVideo/mandala.mp4";
import tree from "./treeofLife.png";
import sac from "./Sac.png";
import { MenuBar } from "../../components/MenuBar/MenuBar.jsx";
import { Footer } from "../../components/Footer/FooterSac";
import { TOADialog } from "../../components/Toa/TOADialog";
import "./AdvanceSacrifice.css";

const RollOver = () => {
  const [rollover, setRollover] = useState(false);
  return (
    <div onMouseEnter={() => setRollover(true)} onMouseLeave={() => setRollover(false)} style={{ textAlign: "center" }}>
      {rollover === true && <img src="assets/misc/mandalaGif.gif" alt="loading..." width="900" />}
      {rollover === false && <img src="assets/misc/rollover.png" alt="loading..." width="300" />}
    </div>
  );
};

const AdvanceSacrifice = () => {
  return (
    <div>
      <CssBaseline />
      <div>
        <MenuBar />
      </div>
      <div className="wrapper">
        <div className="video">
          <video src={video} autoPlay controls />
        </div>
        <div className="card">
          <div className="header">
            <h1> WELCOME TO THE MANDALA </h1>
            <div className="rb" style={{ height: "0.2px", width: "50vw", textAlign: "center" }}></div>
            <br />
            <div className="text" style={{ textAlign: "center" }}>
              Congratulations!
            </div>
            <br />
            <div className="text">
              <div style={{ textAlign: "center" }}>
                You have gained early access to the Mandala Sacrifice token event. Mandala is offering all NFT holders -- Sovereigns, Regens, and Cryptonauts -- the utility and opportunity to
                sacrifice to the Tree of Life and manifest the abundance paradigm.
              </div>
              <br />
              <div>
                <div style={{ textAlign: "center" }}>
                  <TOADialog height={400} />
                  <br />
                  <br />
                  Mandala will be offering a public Sacrifice token event in 2024. Our NFT community can participate now to receive the maximum multiplier (5X). <br /> <br />
                </div>
              </div>
            </div>
          </div>
          <div className="header">
            <h2>TO PARTICIPATE </h2> <br />
          </div>
          <div className="rb2" style={{ height: "0.2px", width: "30vw", textAlign: "center" }}></div>
          <br />
          <div className="text">
            <div className="bgr">
              <div className="bg" style={{ width: "45vw", textAlign: "left", margin: "0 auto" }}>
                1. Connect your wallet with a Sovereign(Cardano), Regen(Ethereum) or Cryptonaut(Polkadot). <br />
                <br />
                2. Claim your three (3) unique, one-use links per NFT to the secret advance sacrifice page <br />
                <br />
                3. Use the links for yourself and/or to share with friends (and receive a referral bonus)
              </div>
            </div>
          </div>
          <br />
          <div className="header">
            <h2> HOW IT WORKS </h2>{" "}
          </div>{" "}
          <br />
          <div className="rb2" style={{ height: "0.2px", width: "30vw", textAlign: "center" }}></div>
          <br />
          <div className="text">
            Advanced Sacrifice participants send a crypto amount of their choosing to the{" "}
            <a style={{ color: "white" }} href="https://sacrifice.enterthemandala.com" alt="page link" target="_blank" rel="noreferrer">
              Tree of Life
            </a>
            , and they are gifted a unique Decentralized ID (DID) for special membership in the{" "}
            <a style={{ color: "#FFF" }} href="https://enterthemandala.com" alt="main page link" target="_blank" rel="noreferrer">
              {" "}
              Mandala Metaverse.{" "}
            </a>
            <br />
            <br />
            Each DID contains a record of the amount sacrificed plus the 5X multiplier to be redeemed later for Mandala tokens. In the meantime, you will be able to see your sacrifice points and get
            your DID in the&nbsp;
            <a style={{ color: "#FFF" }} href="https://enterthemandala.app" alt="clan app link" target="_blank" rel="noreferrer">
              Mandala Clan App
            </a>
            .
            <br />
            <br />
          </div>
          <div className="sac">
            <img style={{ width: "60vw", boxShadow: "-10px -10px 12px #080216, 3px 3px 12px #1a0846" }} src={sac} alt="sac" />
          </div>
          <br />
          <br />
          <div className="text">
            Make sure to use your referral links wisely. You will receive a 3% bonus of sacrifice points for the total amount sacrificed through them (when the Sacrifice is concluded). <br />
            <br />
            Sacrifice to build the world’s first{" "}
            <a style={{ color: "#FFFFFF" }} href="https://enterthemandala.com/" alt="main" target="_blank" rel="noreferrer">
              Enlightenment Simulator
            </a>{" "}
            for future generations designed with first principles of sovereignty, decentralization, freedom, and enlightenment.
            <br />
            <br />
            <br />
          </div>
          <>
            <RollOver />
          </>
        </div>

        <div className="rainbow" style={{ margin: "0 auto" }}>
          <div style={{ marginTop: 10 }}>Disclaimer</div>
          <div style={{ padding: "22px" }}>
            You are sacrificing to prove how strongly you believe that the power of consciousness is the key to changing the world for good. This is a powerful sovereign declaration. By sacrificing to
            the Tree of Life, you are enacting the oldest human ritual. You must have no expectation of profit from the work of others. The set of people who have sacrificed to show their commitment
            to this sovereign declaration makes a great set of people to airdrop things to. Sacrifice points are not meant to have any monetary value. Remember, you are not buying anything – the world
            is just noticing you are amongst a group of people that sacrificed to make a sovereign declaration. Some countries tax their citizens when they receive things of value. Mandala and all the
            tokens on it are designed to have no value, only the concept of interconnectivity between two beings, which is the natural way of the world. Mandala has a set of principles which are
            decided by ALL users, all members of the Mandala group. This is not an investment, it is the ability to join the story. The only thing we know of the story so far is that the Token will be
            dropped to a sovereign group and that it is called the Mandala Token. Join the journey.
            <br />
            <br />
            Before you participate you must consult your own legal and financial professionals, as nothing written here should be considered professional advice. Mandala Tokens are not a security.
            They are not a financial instrument. You should not treat Mandala Tokens as an investment or expect any type of profit from them.
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          <a style={{ color: "white", fontSize: "100px" }} href="https://sacrifice.enterthemandala.com/" target="_blank" rel="noreferrer">
            DYOR
          </a>
        </div>
        <div className="text" style={{ textAlign: "center" }}>
          (Do Your Own Research)
        </div>
        <br />
        <div className="tree">
          <a href="https://sacrifice.enterthemandala.com/" target="_blank" rel="noreferrer">
            <img style={{ width: "40vw" }} src={tree} alt="tree of life" />
          </a>
        </div>
        <br />
        <br />
      </div>
      <Footer />
    </div>
  );
};

export default AdvanceSacrifice;
