
export const fetchCoinPrice = async (asset) => {
  // console.log("assetCoinPrice", asset);
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };
  try {
    const getcoinInfo = await fetch(`https://api.coingecko.com/api/v3/simple/price?ids=${asset}&vs_currencies=usd`, requestOptions);
    const data = await getcoinInfo.json();
    // console.log("getcoinInfo", data);
    return data;
  } catch (error) {
    console.log("fetch coin error", error);
    return { [asset]: { usd: 0 } };
  }
};