import React, { useState } from 'react';

export const ToaText = () => {
	return(
		<body dir="ltr" style={{backgroundColor: "#ffffff", color:"#000000", maxWidth:"8.5in", marginTop:"0.5in", marginBottom:"0.6in", marginLeft:"1in", marginRight:"1in", padding:"10px" }}>
			<p className="P1_borderStart">
				<span className="T1">Simple Advanced Sacrifice Agreement </span>
				<br/><br/><br/>
				This Simple Advanced Sacrifice Agreement (SASA) is a binding contract between you (You) and Good Tidings Group Ltd, a limited liability company based in the Republic of Seychelles, sometimes known as Mandala (The Company or Mandala). 
				<br/><br/>
				In entering into this Agreement (SASA) you are agreeing to be bound by the terms contained within. You should read this Agreement fully. Once you have signed/executed this Agreement, you will be bound by its terms. 
				<br/><br/>
				Mandala is conducting a Token Sacrifice Event, whereby any person is free to Sacrifice their Crypto. Sacrificing your Crypto to Mandala is optional and should not be done under any duress or with the belief you are obtaining any kind of return.
				<br/><br/>
				<span className="T1">
				1. Preliminary Nature of this Agreement 
				</span>
				<br/>
				The full Sacrifice has not yet begun. You have been selected to make your Sacrifice early. You may sacrifice Digital Assets or Cryptographic Tokens. 
				 
				The Sacrifice is designed to allow Mandala to begin the implementation of Mandala Tokens within the respective blockchain networks. In technological terms, this is sometimes referred to as a ‘test period’. 
				<br/><br/>
				This Preliminary Sacrifice is required in order to ensure Mandala can test its Tokens, build relationships within respective Crypto communities, and examine the impact on liquidity markets before undertaking the full sacrifice. 
				<br/><br/>
				In sacrificing your Tokens you understand and agree that you are losing the items that you sacrifice. 
				They are irrevocably given to Mandala and sacrificed to the Tree of Life. All Sacrifices are voluntary, and are given as a donation to the Company. 
				The Company will welcome all participants into the ecosystem with membership Tokens. 
				 <br/><br/>
				The Tree of Life is a functional skill tree and main plot point in the Mandala storyline. 
				As the Tree of Life grows, it will use the sacrificed Crypto to grow and, in doing so, grow the Mandala ecosystem and the Enlightenment Simulator.
				<br/><br/>
				<span className="T1">
				2. Full Sacrifice Terms 
				</span>
				<br/>
				This is a preliminary Agreement, which means You are sacrificing your Tokens under this Agreement to Mandala for the right to participate in the full Sacrifice, which will happen at a later date and time to be determined. 
				<br/><br/>
				In entering into this SASA, you understand that there is a risk that certain terms may be modified, amended, extended, or removed in the full Sacrifice.
				<br/><br/>
				If there is any amendment to this SASA, you will be notified in the Full Sacrifice Agreement. Upon notice of the same, if there are any Terms or Modifications you do not agree with, you may sacrifice your Mandala Tokens. 
				Upon sacrificing your Mandala Tokens instead of any other Crypto, these shall be burned by Mandala. 
				<br/><br/>
				<span className="T1">
				3. Termination 
				</span>
				<br/>
				This contract will terminate when one of the following occurs: 
				<br/><br/>
				(1) You receive your Mandala Tokens, or; 
				<br/>
				(2) You elect to sacrifice your Mandala Tokens rather than receive them. 
				<br/><br/>
				Upon termination, this contract shall be deemed as satisfied. 
				Upon termination, this SASA shall terminate and any and all obligations under it shall be terminated. 
				<br/><br/>
				Waivers, warranties, arbitration clauses and indemnities shall remain in full force and effect. 
				<br/><br/>
				<span className="T1">
				4. Securities Waiver 
				</span>
				<br/>
				A Sacrifice is not a purchase. It is not a swap. 
				Mandala Tokens have two functions: First, they are designed as a pure membership Token for the Mandala universe (Membership Token). 
				Secondly, they are engagement Tokens within an online ecosystem and Enlightenment Simulator (Game Tokens).
				<br/><br/>
				Filling the gap does not mean that these Tokens are to be treated the same as the Sacrificed Tokens. 
				Their use is to maintain liquidity within the coins which are sacrificed so those chains are not negatively impacted. 
				This is a necessity because of the nature of blockchain technology. 
				<br/><br/>
				Mandala Tokens hold no value. 
				They are not a system of payment. 
				They are not a monetary instrument. 
				They are Game Tokens and have no real world value. 
				Mandala Tokens are only a Membership and Game Token, which will allow You to participate in an ecosystem, storyline, and game. 
				<br/><br/>
				In agreeing to this SASA, you hereby warrant and undertake that you do not believe Mandala Tokens are a security. 
				<br/><br/>
				You further undertake and warrant that: 
				(1) You are not investing Money or your Crypto. There is no expectation or belief that you will receive a return, interest, or profit in Mandala Tokens or by using Mandala Tokens. 
				You are not participating in this sacrifice because you believe you are ‘investing’. Sacrificing your Tokens is not investing. 
				(2) You are not entering into a group, enterprise, or collective scheme. Mandala users and Mandala are all separate from one another and only engage with each other in a game world universe. 
				(3) You are in no way expecting any profit or other financial benefit from owning, using, or holding Mandala Tokens. 
				(4) Mandala is not doing anything which will impact the price of the Mandala Tokens, and any value affixed to them is done so to be representative of their associated value on the chains in which they were swapped, 
				even in the case where the price is aggregated across all Tokens. 
				<br/><br/>
				MANDALA TOKENS ARE NOT A SECURITY. 
				YOU ARE BEING GIVEN NO RIGHTS. 
				MANDALA TOKENS ARE WORTHLESS AND HOLD NO VALUE. 
				IN ENTERING INTO THIS AGREEMENT YOU HEREBY WARRANT AND UNDERTAKE THAT YOU DO NOT BELIEVE THAT THE SACRIFICE OF TOKENS IS BEING DONE AS A SECURITY AND YOU ARE NOT ENTERING INTO THIS AGREEMENT WITH THE BELIEF THAT YOU SHALL RECEIVE ANY KIND OF PROFIT. 
				<br/><br/>
				DO NOT PARTICIPATE IF YOU BELIEVE THAT MANDALA TOKENS ARE A SECURITY. YOU ARE PARTICIPATING IN A SACRIFICE TO THE TREE OF LIFE THAT IS PART OF A STORY WITHIN A GAME. YOU CAN USE THE TOKENS IN THE GAME ONLY. 
				<br/><br/><br/>
				<span className="T1">
				5.
				</span>
				<span className="T1">
				Method of Sacrifice 
				</span>
				<br/>
				Users will be able to take part in the Sacrifice through the company website. 
				This is not a general promoted sale. This sale is secret and for members only. 
				Sacrifice can only be accessed through a referral code or via a code that is imbedded within an NFT. 
				<br/><br/>
				You can only participate in the Sacrifice after you have received a private invitation, read all of the documents, and agreed to this Sacrifice document, along with all other terms. 
				 <br/><br/>
				You will also receive a code by which you may sacrifice your Tokens if you do not agree to any of the Amendments.
				<br/><br/>
				Once you have selected your chain, you can then sacrifice to the Tree of Life. 
				Those who perform a sacrifice shall receive a DID that shall have a record of how much was sacrificed to the Tree of Life. 
				This DID will be used to allow you to redeem your Mandala Tokens to become involved in the game. <
				br/><br/>
				DO NOT LOSE YOUR DID. 
				IF YOU LOSE YOUR DID, 
				YOU WILL BE UNABLE TO PARTICIPATE IN THE SACRIFICE. 
				THE DID SHALL CONTAIN A RECORD OF WHAT YOU HAVE SACRIFICED. 
				IF YOU LOSE YOUR DID OR YOUR WALLET CONTAINING YOUR DID, YOU WILL NOT BE ABLE TO RECEIVE ANY TOKENS AND CONTINUE TO PARTICIPATE IN THE GAME. 
				<br/><br/><br/>
				DO NOT PARTICIPATE IN THIS SASA IF YOU DO NOT UNDERSTAND HOW A SACRIFICE WORKS. 
				<br/><br/>
				<span className="T1">
				6. Receiving Mandala Tokens 
				</span>
				<br/>
				In order to participate in the story and game fully, Mandala Tokens will be issued to you. Mandala is creating multiple tokens on multiple chains; this is designed to ensure that the Tokens sacrificed do not impact the overall liquidity on the Crypto Market, the underlying technology, and allow users to participate fully within the game. <br/><br/>When you sacrifice your Tokens, you will be free to select which chain you wish your Mandala tokens to be received on. You may only sacrifice Tokens which are on the same chain as the Mandala Tokens you receive. <br/><br/>In sacrificing your Tokens under this Agreement, you are hereby surrendering any and all ownership rights to those Tokens. A surrender is not a purchase or swap. You will lose your Crypto indefinitely without any rights to get it back. TOKENS THAT HAVE BEEN SACRIFICED CANNOT BE RETURNED TO YOU. A SACRIFICE IS A SURRENDER OF YOUR TOKENS. IT IS NOT A LOAN, LENDING, SWAP, BORROW, PURCHASE OR OTHER REVOKABLE METHOD. <br/><br/>Once You participate in the Sacrifice, you will receive your Sacrifice Points. These will be tokenized one to one when the Mandala Token is minted. Sacrifice Points may include a multiplier as a reward, or exclusion from vesting period, for early participation in the Sacrifice. Full details of how many Sacrifice Points will be received is displayed on the website. </p>
				<p class="Body_20_A">
				<br/>
				<span className="T1">
				7. Indemnity 
				</span>
				<br/>
				You hereby agree to hold Mandala, including its officers, directors, shareholders, affiliates, and users, 
				harmless for any and all claims or losses arising from or out of your use of, ownership of, or holding of Mandala Tokens or your participation in this SASA. 
				<br/><br/>
				You agree to indemnify and hold harmless Mandala in relation to any Securities Laws or claims of Securities Laws breaches caused by, or from, your use, ownership, or handling of Mandala Tokens, including governmental or private actions. 
				<br/><br/>
				There are no claims as to Alter Ego companies and you waive any and all claims as to any Alter Ego Status. 
				Good Tidings Group Ltd is an independent company and you hereby undertake that you do not believe that Good Tidings Group Ltd is part of a group which has any entity within the United States of America. 
				You warrant that you have undertaken full due diligence before participating in the Sacrifice and confirm that the Company is not part of a group that has any nexus, or believed nexus, within the United States of America. 
				<br/><br/>
				You shall indemnify Good Tidings Group Ltd for any losses associated with your participation in the Sacrifice. 
				<br/><br/>
				You hereby waive any and all claims against any Director, Member, Advisor, or Shareholder to the Mandala project as working with or for Good Tidings Group Ltd. 
				You acknowledge that this Agreement exists only between you and the Company and no other party shall have liability under or arising from your involvement in it or the Sacrifice. 
				<br/><br/>
				<span className="T1">
				8.
				</span> 
				<span className="T1">
				Warranties 
				</span>
				<br/>
				You warrant and agree that: 
				<br/><br/>
				<span>
				 (1) You have the full legal and mental capacity to enter into this agreement. 
				</span>
				<br/><br/>
				<span>
				 (2) Entering into this agreement does not constitute the engagement of a securities contract or other financial instrument in your country. 
				</span>
				<br/><br/>
				<span>
				 (3) Cryptographic assets are not banned, restricted or prohibited in your country. 
				</span>
				<br/><br/>
				<span>
				 (4) If required by a competent government authority or law enforcement agency, you will 
				</span>
				<span>
				 
				</span>
				<span>
				 provide your identification documents and ID yourself. 
				</span>
				<br/><br/>
				<span>
				 (5) You are not entering into this SASA with the intention of making profit. 
				</span>
				<br/><br/>
				<span>
				 (6) You are not sacrificing tokens which do not belong to you. 
				</span>
				<br/><br/>
				<span className="T1">
				9. Waivers
				</span>
				<br/>
				No action on the part of Mandala will constitute a waiver of any part of these terms. 
				<br/><br/>
				You hereby waive any right to class, group, or unified legal actions by a group or groups of individuals. No class action shall be allowed. <br/><br/>
				<span className="T1">
				10.
				</span> 
				<span className="T1">Governing law 
				</span>
				<br/>
				This Agreement shall be governed by the laws of Seychelles within the SIAC. You shall cover all costs associated with Arbitration, regardless of the initiating party. 
				Both parties agree to arbitrate any dispute arising from or out of this Agreement. 
				<br/><br/>
				The Arbitration shall be held within the Republic of Seychelles and shall be conducted in English. 
				The Arbitration shall appoint one Arbitrator to preside over any disputes between the Parties. 
				All costs of the Arbitration shall be covered by You, regardless of any award made by the Arbitration body. 
				<br/><br/>
				Before You commence arbitration, you shall provide written notice of complaint to the company-registered office that you intend to commence arbitration and provide the company 45 days to respond to your complaint. 
				</p>
				<p className="Body_20_A_borderEnd">
				<br/>
				Welcome to the Mandala. 
				<br/>
			</p>
		</body>
	)

}