import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip } from "@mui/material";
import { MandalaClanAppBackendApi } from "../../../api/MandalaApi";
import { useAccount } from "wagmi";
import { EVMWallet } from "../../ETHWallet/EthWallet";

export const EthereumRevealDialog = () => {
  const [open, setOpen] = useState(false);
  const [copyStatus, setCopyStatus] = useState({});
  const [sacrificeAddr, setSacrificeAddr] = useState("0x23Bc33E9AAbb63191D4763aF050902c0C1891bFC");
  const [useStatus, setUsedStatus] = useState();
  const { isConnected, address } = useAccount();
  const [saccrificeOk, setSaccrificeOk] = useState(false);

  const handleCopyStatus = (prop) => (event) => {
    // console.log(prop)
    navigator.clipboard.writeText(prop);
    setCopyStatus({ ...copyStatus, [prop]: event.target.value });
  };

  const getQueryString = async (name, url = window.location.href) => {
    name = name.replace(/[[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return results[2].replace(/\+/g, " ");
  };

  const sacrificeLink = async () => {
    // setUsedStatus("SACRIFICING LINK...");
    const uuid = await getQueryString("UUID");
    const checkRefLinkRes = await MandalaClanAppBackendApi.sacrificeRefLink(address.toLowerCase(), uuid, "check", "", "", "", "", "", "", "");
    console.log("checkRefLinkRes", checkRefLinkRes);
    if (checkRefLinkRes[0].ref_used === "true" || checkRefLinkRes.length === 0) return setUsedStatus(false);
    const useLinkRes = await MandalaClanAppBackendApi.sacrificeRefLink(address.toLowerCase(), uuid, "", "5", "", "", "", "", "", "ethereum");
    console.log("useLinkRes", useLinkRes);
    useLinkRes === "ok" && setSaccrificeOk(true);
  };

  const handleOpen = () => {
    sacrificeLink();
    setUsedStatus(true);
    setOpen(true);
  };

  const handleClose = () => {
    setUsedStatus();
    setOpen(false);
  };

  return (
    <>
      {isConnected ? (
        <Tooltip title="Your sacrifice link will be used up when you press this button.">
          <Button onClick={handleOpen} variant="contained" style={{ color: "#fff", margin: 10 }}>
            REVEAL SACRIFICE ADDRESS
          </Button>
        </Tooltip>
      ) : (
        <>
          <div>Please Connect Ethereum Wallet to Sacrifice:</div>
          <EVMWallet />
        </>
      )}

      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"lg"} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" style={{ color: "#fff" }}>
          Ethereum Sacrifice
        </DialogTitle>
        <DialogContent>
          {useStatus === true && (
            <>
              <div>
                Send your ETH or ERC20 token to this address to complete your sacrifice:
                <br />
                <br />
                {sacrificeAddr}
                <br />
                <button value="copied" onClick={handleCopyStatus(sacrificeAddr)}>
                  Copy
                </button>
                <br />
                {copyStatus[sacrificeAddr] && <div>{copyStatus[sacrificeAddr]}</div>}
              </div>
              <br />
              <div style={{ textAlign: "center" }}>Once your sacrifice has been confirmed on the Ethereum blockchain, you will be able to view a record of it in your Clan app profile.</div>
              {/*
              <div style={{ textAlign: "center" }}>
                <Button onClick={() => sacrificeLink()} variant="contained" style={{ color: "#fff", margin: 10 }}>
                  COMMIT SACRIFICE
                </Button>
              </div>
              */}
            </>
          )}
          {/*saccrificeOk === true && <div style={{ textAlign: "center" }}> Sacrifice Commited.</div>*/}
          {useStatus === false && <div>This link has already been used or doesn't exist.</div>}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
