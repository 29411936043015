import { KupoAPI } from "../api/KupoApi";
import { CarpApi } from "../api/CarpApi";
import { fromHex } from "../utils/hextools";
import { decode } from 'cbor-x';

export const parseUtxos = async ( results ) => {
  let lovelaceTotal = 0;
  let utxos = [];
  try{
    // console.log("Kupo result", results)
    await Promise.all(
      await results.map( async ( utxo ) => {
        // console.log(utxo)
        lovelaceTotal = lovelaceTotal + utxo.value.coins;
        // console.log(Object.keys(utxo.value.assets).length);
        Object.keys(utxo.value.assets).length > 0 && Object.entries(utxo.value.assets).map( async ( asset ) => {
          utxos.push({ "TxId": utxo.transaction_id, "txIndex": utxo.output_index, "datum":  utxo.datum, "asset": asset[0], "assetAmount": asset[1], "meta": [], "loveLace": utxo.value.coins  });
        })
        // // console.log(utxo[1].value.coins)
        utxos.push({ "TxId": utxo.transaction_id, "txIndex":  utxo.output_index, "datum":  utxo.datum, "asset": null, "loveLace": utxo.value.coins  });
      })
    )
    utxos.push( {"lovelaceTotal": lovelaceTotal} )
    return(utxos);
  }catch( error ){
    // console.log(error);
    return("error")
  };
};

export const parseMetadata = async ( utxos ) => {
  let assetJSON;
  let resultsMeta = [];
  const data = Promise.all(
    utxos.map( async (utxo) =>{
      // utxo.asset && console.log("parseMetadata Utxo", utxo.asset)
      if(utxo.asset) assetJSON = {
        "assets": {
          [splitAsset(utxo.asset)[0]]: [ splitAsset(utxo.asset)[1]]
        }
      };
      let metadata = [];
      if(utxo.asset) resultsMeta = await CarpApi(assetJSON);
      // console.log("assetJSON", assetJSON);
      // resultsMeta.length === 0 && !utxo.lovelaceTotal && console.log(utxo);
      // Object.keys(resultsMeta).length > 0 && Object.keys(resultsMeta.cip25).length > 0 && !utxo.lovelaceTotal && console.log(resultsMeta)
      if(Object.keys(resultsMeta).length > 0 && Object.keys(resultsMeta.cip25).length > 0 && !utxo.lovelaceTotal) metadata = await metadataCbortoJSON(resultsMeta.cip25[splitAsset(utxo.asset)[0]][splitAsset(utxo.asset)[1]]);
      // console.log(utxo);
      utxo.asset && !utxo.lovelaceTotal && utxo.meta.push(metadata);
      return(utxo);
    })
  )
  return(data);
};

// this will parse out policies you need to work with and pout them in json object format along with their UTXO info
export const parsePolicies = async ( utxos, policies ) => {
  // console.log("parsing", policies);
  //mandala policies
  // policies = [ "8001dede26bb7cbbe4ee5eae6568e763422e0a3c776b3f70878b03f1", "a484ff902de682d1c05158daf246b40b533a7a2b2e9f81a41ad48fe4" ];
  // console.log("utxos", utxos);
  // console.log("policies", policies);
  let parsedPolicies = [];
  await Promise.all(
    await utxos.map( ( utxo ) => {
    // console.log(utxo)
    utxo !== undefined &&
      policies.map(( policy ) =>
        utxo.asset && splitAsset(utxo.asset)[0] === splitAsset(policy)[0] && parsedPolicies.push(utxo)
      );
      utxo.lovelaceTotal && parsedPolicies.push(utxo)
    })
  );
  // console.log("parsedPolicies", parsedPolicies);
  return(parsedPolicies);
};

const fetchCip25Metadata = async ( asset ) => {
  let metadataDecoded;
  const assetJSON = {
    "assets": {
      [ asset.split(".")[0] ]:[ asset.split(".")[1] ]
    }
  };
  // console.log("asset", asset);
  try{
    const metadataRes = await CarpApi(assetJSON);
    // console.log("metadataRes", Object.keys(metadataRes.cip25).length);
    if(Object.keys(metadataRes.cip25).length > 0) metadataDecoded = await metadataCbortoJSON(metadataRes.cip25[asset.split(".")[0]][asset.split(".")[1]]);
    // console.log("metadataDecoded", metadataDecoded);
    return(metadataDecoded);
  }catch(error){
    console.log("fetchCip25Metadata error", error);
    return("error");
  };
};

export const splitAsset = ( asset ) => {
  return asset.split(".");
};

const metadataCbortoJSON = async (cborString) => {
  try{
    const metadataJSON = await decode(fromHex(cborString));
    // console.log("cborJson", metadataJSON);
    return(metadataJSON);
  }catch(error){
    console.log("cborJson Error", error)
    return(error);
  }
};
// 546f6b68756e