import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AdvanceSacrifice from "./containers/AdvanceSacrifice/AdvanceSacrifice";
import reportWebVitals from "./reportWebVitals";
import { ReusableProvider } from "reusable";
import { configureChains, createClient, WagmiConfig, mainnet, Chain } from "wagmi";
import { publicProvider } from "wagmi/providers/public";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { InjectedConnector } from "wagmi/connectors/injected";
import { ThemeProvider } from "@mui/material";
import { themeDark } from "./themes/theme";
import "./index.css";

const astar = {
  id: 592,
  name: "Astar",
  network: "Astar",
  nativeCurrency: {
    decimals: 18,
    name: "Astar",
    symbol: "ASTR",
  },
  rpcUrls: {
    public: { http: ["https://evm.astar.network"] },
    default: { http: ["https://evm.astar.network"] },
  },
  blockExplorers: {
    default: { name: "SubScan", url: "https://astar.subscan.io" },
  },
};

const { chains, provider } = configureChains([mainnet, astar], [alchemyProvider({ apiKey: "IC_ntRsmFeKPzCI82CrPouCWmiDp-JNe" }), publicProvider()]);

const wagmiClient = createClient({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new InjectedConnector({
      chains,
      options: {
        name: "Talisman",
        getProvider: () => (typeof window !== "undefined" ? window.talismanEth : undefined),
        shimDisconnect: true,
        shimChainChangedDisconnect: true,
      },
    }),
    new InjectedConnector({
      chains,
      options: {
        name: "Subwallet",
        getProvider: () => (typeof window !== "undefined" ? window.SubWallet : undefined),
        shimDisconnect: true,
        shimChainChangedDisconnect: true,
      },
    }),
  ],
  provider,
});

const routing = (
  <Router>
    <Routes>
      <Route path="/" element={<AdvanceSacrifice />} />
    </Routes>
    <Routes>
      <Route path="/AdvanceSacrifice" element={<AdvanceSacrifice />} /> 
    </Routes>
  </Router>
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ReusableProvider>
    <WagmiConfig client={wagmiClient}>
      <React.StrictMode>
        <ThemeProvider theme={themeDark}>{routing}</ThemeProvider>
      </React.StrictMode>
    </WagmiConfig>
  </ReusableProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
