export const getTokenPrice = async ( tokens ) => {
  let newTokens = [];

  await tokens.map( ( token ) => {
    newTokens.push(token.replace(".", ""));
  });

  let myHeaders = new Headers();
  myHeaders.append("x-api-key", "TJyyaDrxr35ewWVi4PZNF96aKWYlGToF");
  myHeaders.append("Content-Type", "application/json");

  let raw = JSON.stringify(
    newTokens
  );

  let requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  try{
    const getResponse = await fetch("https://openapi.taptools.io/api/v1/token/prices", requestOptions);
    const data = await getResponse.json();
    return(data);
  }catch(e){
    console.log(e);
    return e;
  }


};

export const getAdaPrice = async () => {
  let myHeaders = new Headers();
  myHeaders.append("x-api-key", "TJyyaDrxr35ewWVi4PZNF96aKWYlGToF");

  let requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  try{
    const getResponse = await fetch("https://openapi.taptools.io/api/v1/token/quote", requestOptions);
    const data = await getResponse.json();
    return(data);
  }catch(e){
    console.log(e);
    return e;
  }
  

}