import React, { useState, useEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Avatar, Stack, Tooltip, Grid, Box, Paper } from "@mui/material";
import { CardanoWalletConnect } from "../../ADAWallet/CardanoWalletConnect";
import { connectedWallet } from "../../../hooks/connectedWalletHook";
import { KupoAPI } from "../../../api/KupoApi";
import { parseUtxos, parseMetadata, parsePolicies } from "../../../utils/UTXOtools";
import { CardanoReferalLinks } from "./CardanoReferalLinks";
import CircularProgress from "@mui/material/CircularProgress";

export const CardanoLinksDialog = () => {
  const [open, setOpen] = useState(false);
  const [adaWAllet, setAdaWallet] = connectedWallet();
  const [adaAssets, setAdaAssets] = useState();
  const [statusAssets, setStatusAssets] = useState();

  const handleOpen = () => {
    setAdaAssets();
    setStatusAssets();
    initAll();
    setOpen(true);
  };

  const handleClose = () => {
    setAdaAssets();
    setStatusAssets();
    setOpen(false);
  };

  const getAddressUtxos = async () => {
    const stakeAddress = JSON.parse(adaWAllet).stakeAddr;
    // console.log("address", address);
    // const address = "stake1uxpxcyjnmq4636cfnw0au6r6y6wyqnfvyr6czawjctglxlcmqalx6";
    // const address = "stake1ux65m7rkjeesuys3rgwkd7llhclzrj20yrhm2l4ah3hvrhszz74ta";

    const addressUtxos = await KupoAPI(`matches/${stakeAddress}?unspent`);
    console.log("addressUtxos", addressUtxos);
    return addressUtxos;
  };

  const initAll = async () => {
    let rawUtxos = [];
    let parsedUtxos = [];
    let parsedUtxoMetadata = [];
    let parsedPolicies = [];
    setAdaAssets();
    setStatusAssets();
    console.log(adaWAllet)
    let policies = ["8001dede26bb7cbbe4ee5eae6568e763422e0a3c776b3f70878b03f1", "a484ff902de682d1c05158daf246b40b533a7a2b2e9f81a41ad48fe4"];
    if (adaWAllet !== "null")
      setStatusAssets(
        <div>
          looking for links <br /> <CircularProgress />{" "}
        </div>
      );
    if (adaWAllet !== "null" && adaWAllet !== null) rawUtxos = await getAddressUtxos();
    // console.log("rawUtxos", rawUtxos);
    if (rawUtxos.length > 0) parsedUtxos = await parseUtxos(rawUtxos);
    // console.log("parsedUtxos", parsedUtxos);
    if (parsedUtxos.length > 0) parsedUtxoMetadata = await parseMetadata(parsedUtxos);
    // console.log("parsedUtxoMetadata", parsedUtxoMetadata);
    if (parsedUtxoMetadata.length > 0) parsedPolicies = await parsePolicies(parsedUtxoMetadata, policies);
    console.log("parsedPolicies", parsedPolicies);
    setStatusAssets();
    setAdaAssets(parsedPolicies);
  };

  const ShowWalletButton = () => {
    return (
      <>
        <Box sx={{ width: "100%" }}>
          <CardanoWalletConnect />
          {adaWAllet === "null" && (
            <div>
              <br />
              Mandala Sovereign holders, connect your wallet and get your Sacrifice links.
            </div>
          )}
        </Box>
        <br />
        <Box sx={{ width: "100%" }}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12}>
              {adaAssets && adaAssets.length > 1 && <div>Sovren, claim your Sacrifice links: {(adaAssets.length - 1) * 3}</div>}
              {statusAssets && <div>{statusAssets}</div>}
              {adaAssets && adaAssets.length > 1 && <CardanoReferalLinks assets={adaAssets} />}
              {adaWAllet !== "null" && adaAssets && adaAssets.length === 1 && <div>You do not have any Mandala Sovereign NFTs, so you do not have any Sacrifice links. </div>}
            </Grid>
          </Grid>
        </Box>
      </>
    );
  };

  useEffect(() => {
    // initAll();
  }, [adaWAllet]);

  return (
    <>
      <Button onClick={handleOpen} variant="contained" style={{ color: "#fff", margin: 10 }}>
        {adaWAllet !== "null" ? (
          <>
            {JSON.parse(adaWAllet)?.walletName} - Cardano
            <br />
            {JSON.parse(adaWAllet)?.stakeAddr.slice(0, 8)}...
            {JSON.parse(adaWAllet)?.stakeAddr.slice(-2)}
          </>
        ) : (
          "Connect Cardano Wallet"
        )}
      </Button>

      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"lg"} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" style={{ color: "#fff", textAlign: "center" }}>
          {!adaAssets && <>Sovereign?</>}
        </DialogTitle>
        <DialogContent>
          <div style={{ marginLeft: "25%" }}>{/* <DisplayCoins /> */}</div>
          <div style={{ textAlign: "center" }}>
            <ShowWalletButton />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
