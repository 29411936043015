import React, { useState, useEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import * as Wallet from "sats-connect";
import { useBtcWalletHook } from "../../hooks/btcWalletHook";

export const BitcoinWalletConnect = () => {  
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(false);
  const [ btcWalletHook, setBtcWalletHook ] = useBtcWalletHook();

  const handleClickOpen = () => {
    pollWallets();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const pollWallets = async () => {
    try {
      const response = await Wallet.request("getInfo", null);
      console.log(response);
      response.status === "success" && getWalletAccounts();
    } catch (err) {
      console.log(err);
    }
  };

  const getWalletAccounts = async () => {
    try {
      const response = await Wallet.request("stx_getAccounts", {}); 
      if (response.status === "success") {
        // alert("Success getting accounts. Check console for results.");
        console.log("Accounts:", response.result.addresses[0]);
        setBtcWalletHook(JSON.stringify(response.result.addresses[0]));

      } else {
        alert("Error getting accounts. Check console for details.");
        console.error(response);
      };
    }catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    // pollWallets();
  }, []);

    return ( 
      <>
      {
      !btcWalletHook ? (
        <Button onClick={handleClickOpen} className="rainbow-box" style={{ margin: "10px 0 0 5px", height: "50px", minWidth: 100, color: "#fff" }}>
          Connect Wallet
        </Button>
      ) : (
        <Button onClick={handleClickOpen} className="rainbow-box" style={{ margin: "10px 0 0 5px", height: "50px", minWidth: 100, color: "#fff" }}>
          {/* <img src={JSON.parse(connectedwallet)?.walletIcon} alt={JSON.parse(connectedwallet)?.walletIcon} height="25" /> */}
          {JSON.parse(btcWalletHook)?.address.slice(0, 8)}...
          {JSON.parse(btcWalletHook)?.address.slice(-2)}
        </Button>
      )
      }
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen={fullScreen}
        maxWidth="sm"
        PaperProps={{
          style: {
            borderRadius: "15px",
            border: "double .2em rgba(51,51,255,0.8)",
            backgroundImage: "linear-gradient(190deg, rgba(255,255,255, .1), rgba(0,0,0, .4))",
            /* filter: "drop-shadow(0 0 8px rgba(51,51,255,0.8))" */
          },
        }}
      >
        <DialogTitle id="alert-dialog-title" style={{ color: "#fff", textAlign: "center", fontSize: "16px", fontWeight: "300" }}>

        </DialogTitle>
        <div style={{ margin: "20px" }}>
        
        </div>
        <DialogContent>

        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
    )

};

